import { ChangeEvent } from 'react';
import { AnswerValue, OptionsAnswer } from '../common/types';

interface TemplateBlockContentMultiSelectProps {
  blockId: number;
  answer: OptionsAnswer;
  handleBlockAnswerUpdate: (blockId: number, newValue: AnswerValue) => void;
}

export default function TemplateBlockContentMultiSelect({
  blockId,
  answer,
  handleBlockAnswerUpdate
}: TemplateBlockContentMultiSelectProps) {
  /**
   * Call parent state setter to update template block
   * answer value when a new option is selected
   */
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Create a new dictionary with all of the original answers
    const updatedAnswers: OptionsAnswer = { ...answer };
    // Iterate over each checkbox input
    Object.keys(updatedAnswers).forEach((option) => {
      // Update the corresponding value in the dictionary based on the checkbox state
      if (option === e.target.value) {
        updatedAnswers[option] = e.target.checked;
      }
    });
    // Set upstream state
    handleBlockAnswerUpdate(blockId, updatedAnswers);
  };

  return (
    <div className="w-fit p-3 pr-10 border text-sm text-gray-900">
      {Object.keys(answer).map((option) => (
        <div key={option} className="flex items-center mb-2.5">
          <input
            type="checkbox"
            id={option}
            value={option}
            checked={answer[option]}
            onChange={(e) => onChange(e)}
            className="mr-2"
          />
          <label htmlFor={option}>{option}</label>
        </div>
      ))}
    </div>
  );
}
