import { AnswerValue } from '../common/types';

interface TemplateBlockContentShortAnswerProps {
  blockId: number;
  answer: string[];
  handleBlockAnswerUpdate: (blockId: number, newValue: AnswerValue) => void;
}

export default function TemplateBlockContentShortAnswer({
  blockId,
  answer,
  handleBlockAnswerUpdate
}: TemplateBlockContentShortAnswerProps) {
  // Update the answer value in the parent component
  const handleUpdate = (updatedAnswerValue: string, index: number) => {
    const updatedShortAnswerList = [...answer];
    updatedShortAnswerList[index] = updatedAnswerValue;
    handleBlockAnswerUpdate(blockId, updatedShortAnswerList);
  };

  return (
    <ol className="list-[lower-alpha] ml-5 mt-1 space-y-2 w-full">
      {answer.map((answer, index) => (
        <li key={index}>
          <div className="flex items-center">
            <input
              type="text"
              className="flex-grow sm:px-1.5 sm:pb-1.5 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-300 bg-transparent focus:border-indigo-700 placeholder:text-gray-400 sm:text-md sm:leading-6 w-full"
              defaultValue={answer}
              onChange={(e) => handleUpdate(e.target.value, index)}
              required
            />
          </div>
        </li>
      ))}
    </ol>
  );
}
