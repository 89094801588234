import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import {
  CalendarIcon,
  ExclamationTriangleIcon,
  MapPinIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { getMeetingStatus } from '../clients/meetingClient';
import { getTemplateAndBlocksById } from '../clients/templateClient';
import useNavigation from '../common/navigation';
import { Meeting, ProcessingStatus } from '../common/types';
import {
  classNames,
  getFormattedDateString,
  getFormattedTimeString,
  getInitials
} from '../common/utils';
import ErrorBanner from './ErrorBanner';
import UploadButton from './UploadButton';

// Define a type for the button state
export type ButtonState = 'needsUpload' | 'inAppRecording' | 'processing' | 'complete';

export default function MeetingListItem({
  meeting,
  handleOpenUploadModal,
  deleteMeeting
}: {
  meeting: Meeting;
  handleOpenUploadModal: (meetingId: number) => void;
  deleteMeeting: (meetingId: number) => void;
}) {
  const { goToNotePage, goToRecordWithMeetingPage } = useNavigation();

  // Load template by template id
  const { data: template, error: templateError } = useQuery({
    queryKey: ['template', meeting.meetingTemplateId!],
    queryFn: () => getTemplateAndBlocksById(meeting.meetingTemplateId!)
  });

  // Poll for meeting status every 5 seconds if currently processing
  useQuery({
    enabled: meeting.status === ProcessingStatus.PROCESSING,
    queryKey: ['meetingStatus', meeting.id],
    queryFn: async () => {
      const result = await getMeetingStatus(meeting.id!);
      // console.log('polling status for', meeting.id, result);
      if (result === ProcessingStatus.COMPLETED || result === ProcessingStatus.ERROR)
        meeting.status = result;
      return result;
    },
    refetchInterval: 5000 // Poll every 5 seconds
  });

  const date = getFormattedDateString(meeting.dateTime);
  const time = getFormattedTimeString(meeting.dateTime);

  /**
   * Handle button click depending on current meeting state
   * Upload -> Open upload modal
   * Processing -> Do nothing. Button should be inactive
   * Complete -> Open notes page for meeting
   */
  const getOnClickBehavior = (buttonState: ButtonState) => {
    switch (buttonState) {
      case 'needsUpload':
        return handleOpenUploadModal(meeting.id!);
      case 'inAppRecording':
        return goToRecordWithMeetingPage(meeting.id!);
      case 'processing':
        return {};
      case 'complete':
        return goToNotePage(meeting.id!);
      default:
        return {};
    }
  };

  const handleDeleteMeeting = (meetingId: number) => {
    // Only mention transcript and notes if meeting has been processed and those things actually exist
    const alertText =
      meeting.status === ProcessingStatus.COMPLETED
        ? 'Are you sure you want to delete this meeting note? Your meeting, its transcript, and its notes will be deleted. You cannot undo this action.'
        : `Are you sure you want to delete this meeting note? You cannot undo this action.`;

    // Display confirmation dialog
    const isConfirmed = window.confirm(alertText);
    if (isConfirmed) {
      deleteMeeting(meetingId);
    }
  };

  return (
    <li className="relative py-5 xl:static">
      <div className="flex space-x-4 lg:space-x-6">
        <div className="flex lg:items-center">
          {/* Client Initials Blob */}
          <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden bg-indigo-50 rounded-full">
            <span className="font-medium text-indigo-800">{getInitials(meeting.client.name)}</span>
          </div>
        </div>
        <div className="w-full flex-col lg:flex-row flex lg:space-x-6">
          <div className="flex-auto ">
            {/* Meeting Type and Client Full Name */}
            <button
              onClick={() => goToNotePage(meeting.id!)}
              className="group font-semibold inline-flex flex items-center text-left text-lg text-gray-800 xl:pr-0 transition-color duration-300"
            >
              <p className="group-hover:text-indigo-600">
                {meeting.client.name}{' '}
                <span className="mx-1 lg:mx-1.5 text-xl font-light">&rsaquo; </span>
                <span
                  className={classNames(
                    'text-gray-600 text-sm font-normal group-hover:text-indigo-600'
                  )}
                >
                  {template?.template.title}
                </span>
              </p>
            </button>
            {/* Subheading */}
            <dl className="mt-2 flex flex-col text-sm text-gray-500 xl:flex-row">
              {/* Date and Time */}
              <div className="flex items-start space-x-2">
                <dt>
                  <span className="sr-only">Date</span>
                  <CalendarIcon className="mt-0.5 h-4 w-4 text-gray-400 " aria-hidden="true" />
                </dt>
                <dd>
                  <time dateTime={meeting.dateTime.toISOString()}>
                    {date} at {time}
                  </time>
                </dd>
              </div>
              {/* Location */}
              {meeting.location && (
                <div className="mt-2 flex items-start space-x-2 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                  <dt>
                    <span className="sr-only">Location</span>
                    <MapPinIcon className="h-4 w-4 mt-0.5 text-gray-400 " aria-hidden="true" />
                  </dt>
                  <dd>{meeting.location}</dd>
                </div>
              )}
            </dl>
          </div>

          {/* Upload Recording Button */}
          <div className="lg:ml-auto flex flex-none items-center gap-x-4 mt-4 lg:mt-2 lg:mt-0">
            {/* Notification if processing failed */}
            {meeting.status === ProcessingStatus.ERROR && (
              <div className="relative group">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-600 mr-4 " />
                {/* Tooltip */}
                <span className="absolute z-10 block bottom-full overflow-visible bg-gray-500 text-white left-8 top-0 h-16 w-60 h-fit px-3 py-2 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-300">
                  An error occurred while processing your recording. Please try again.
                </span>
              </div>
            )}
            <UploadButton
              getOnClickBehavior={getOnClickBehavior}
              meetingState={meeting.status}
              menuVersion={true}
            />

            <Menu as="div" className="relative flex-none lg:justify-end items-center">
              <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
              </MenuButton>
              <MenuItems
                transition
                className="absolute font-normal right-0 z-10 w-28 px-1.5 origin-top-right text-xs font-semibold rounded-md bg-white text-gray-600 data-[focus]:text-gray-900 py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>
                  <button
                    disabled={meeting.status === ProcessingStatus.PROCESSING}
                    onClick={() => handleDeleteMeeting(meeting.id!)}
                    className="inline-flex items-center block w-full px-2 py-1 leading-6 data-[focus]:text-indigo-700 data-[focus]:bg-indigo-50 disabled:text-gray-400 disabled:bg-gray-50 disabled:data-[focus]:text-gray-400 disabled:data-[focus]:bg-gray-50 disabled:cursor-not-allowed"
                  >
                    <TrashIcon className="h-3.5 w-3.5 mr-2" />
                    Delete
                    <span className="sr-only">
                      , {meeting.client.name} {template?.template.title}
                    </span>
                  </button>
                </MenuItem>
                {/* <MenuItem>
                <button className="inline-flex items-center block w-full px-3 py-1 leading-6 data-[focus]:text-indigo-700 data-[focus]:bg-indigo-50">
                  Archive
                  <span className="sr-only">
                    , {meeting.client.name} {template?.template.title}
                  </span>
                  <ArchiveBoxIcon className="h-3.5 w-3.5 ml-2" />
                </button>
              </MenuItem> */}
              </MenuItems>
            </Menu>
          </div>
        </div>
      </div>

      {templateError && (
        <div className="w-full">
          <ErrorBanner message={templateError.message} />
        </div>
      )}
    </li>
  );
}
