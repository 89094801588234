import {
  ArrowDownIcon,
  ArrowUpIcon,
  QuestionMarkCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { classNames } from '../common/utils';

interface TranscriptSourceBarProps {
  sourceIndices: number[];
  setHighlightedLineIndex: (index: number) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setWindowHash: (index: number) => void;
}

const TranscriptSourceBar = ({
  sourceIndices,
  setHighlightedLineIndex,
  isOpen,
  setIsOpen,
  setWindowHash
}: TranscriptSourceBarProps) => {
  // Note that this is NOT a transcript index, but an index of the sourceIndices array
  const [currentIndex, setCurrentIndex] = useState(0);

  const getPrevIndex = () => (currentIndex === 0 ? sourceIndices.length - 1 : currentIndex - 1);
  const getNextIndex = () => (currentIndex === sourceIndices.length - 1 ? 0 : currentIndex + 1);

  /**
   * Handles the click event for the previous button.
   * Updates the current index, scrolls to the corresponding transcript line,
   * and highlights the transcript line.
   */
  const _handlePrevClick = () => {
    if (sourceIndices.length > 0) {
      const prevIndex = getPrevIndex();
      setCurrentIndex(prevIndex); // update index
      setWindowHash(sourceIndices[prevIndex]);
      setHighlightedLineIndex(sourceIndices[prevIndex]); // highlight transcript line
    }
  };

  /**
   * Handles the click event for the next button.
   * Updates the current index, scrolls to the corresponding transcript line,
   * and highlights the transcript line.
   */
  const _handleNextClick = () => {
    if (sourceIndices.length > 0) {
      const nextIndex = getNextIndex();
      setCurrentIndex(nextIndex); // update index
      setWindowHash(sourceIndices[nextIndex]); // scroll to index
      setHighlightedLineIndex(sourceIndices[nextIndex]); // highlight transcript line
    }
  };

  /**
   * Handles the click event for the close button.
   * Closes the source bar and unhighlights the transcript line.
   */
  const _handleClose = () => {
    setIsOpen(false); // close the bar
    setHighlightedLineIndex(-1); // unhighlight transcript line
    window.history.replaceState(null, '', window.location.pathname + window.location.search); // Remove the hash
  };

  /**
   * Resets the current index and highlights the first source index when the source indices change
   */
  useEffect(() => {
    if (sourceIndices.length > 0) {
      setHighlightedLineIndex(sourceIndices[0]);
      setCurrentIndex(0);
      setWindowHash(sourceIndices[0]); // scroll to index
    } else {
      setHighlightedLineIndex(-1);
      setCurrentIndex(0);
      window.history.replaceState(null, '', window.location.pathname + window.location.search);
    }
  }, [sourceIndices]);

  return (
    <div
      className={classNames(
        isOpen ? 'visible' : 'hidden',
        'flex fixed z-50 inset-y-0 right-0 w-96 h-14 top-0 bg-indigo-600 text-white border-b border-gray-300 text-gray-600 justify-right float-right py-2 px-4 shadow items-center'
      )}
    >
      <div className="flex text-left relative group">
        <QuestionMarkCircleIcon
          className="h-5 w-5 text-gray-400 hover:text-gray-200 transition-stroke duration-300"
          aria-hidden="false"
        />
        {/* Tooltip */}
        <span className="absolute block bottom-full bg-gray-500 left-8 top-0 h-fit w-56 text-white px-3 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
          This bar allows you to view the transcript lines that were used to generate a particular
          answer.
        </span>
      </div>

      <div className="flex ml-auto">
        {/* Current Index */}
        <span className="text-sm">
          Source {sourceIndices.length > 0 ? currentIndex + 1 : 0} of {sourceIndices.length}
        </span>

        {/* Prev and Next Buttons */}
        <button disabled={sourceIndices.length === 0} onClick={_handlePrevClick}>
          <ArrowUpIcon
            className={`h-5 w-5 ml-4 ${sourceIndices.length > 0 ? 'hover:stroke-2 transition-stroke duration-300' : ''}`}
            aria-hidden="false"
          />
        </button>
        <button disabled={sourceIndices.length === 0} onClick={_handleNextClick}>
          <ArrowDownIcon
            className={`h-5 w-5 ml-2 ${sourceIndices.length > 0 ? 'hover:stroke-2 transition-stroke duration-300' : ''}`}
            aria-hidden="false"
          />
        </button>

        {/* Close Button */}
        <button onClick={_handleClose}>
          <XMarkIcon
            className="h-5 w-5 ml-5 hover:stroke-2 transition-stroke duration-300"
            aria-hidden="false"
          />
        </button>
      </div>
    </div>
  );
};

export default TranscriptSourceBar;
