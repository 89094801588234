import { MapPinIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { getTemplateAndBlocksById } from '../clients/templateClient';
import useNavigation from '../common/navigation';
import { Meeting, ProcessingStatus } from '../common/types';
import {
  classNames,
  getFormattedDateString,
  getFormattedTimeString,
  isPast
} from '../common/utils';
import ErrorBanner from './ErrorBanner';

export default function ClientFeedItem({
  meeting,
  isLastInFeed
}: {
  meeting: Meeting;
  isLastInFeed: boolean;
}) {
  // Retrieve template from meeting.templateId
  const templateId = meeting.meetingTemplateId!;
  const { data: templateAndBlocks, error: templateError } = useQuery({
    queryKey: ['getTemplateById', templateId],
    queryFn: () => getTemplateAndBlocksById(templateId)
  });

  const { goToNotePage } = useNavigation();

  return (
    <li key={meeting.id} className="relative flex w-full w-auto gap-x-4">
      <div
        className={classNames(
          isLastInFeed ? 'h-6' : '-bottom-6',
          'absolute left-0 top-0 flex w-6 justify-center'
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>

      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
      </div>

      <div className="flex-auto">
        <div className="flex flex-row">
          <p className="flex-auto py-0.5 text-sm leading-5 text-gray-500">
            You {isPast(meeting.dateTime) ? 'had' : 'have'} a{' '}
            {meeting.status === ProcessingStatus.COMPLETED ? (
              <button
                onClick={() => goToNotePage(meeting.id!)}
                className="font-medium text-indigo-900 border-b p-0.5 border-indigo-300 bg-indigo-50 hover:text-indigo-600 hover:border-indigo-500 transition-color duration-300"
              >
                {templateAndBlocks?.template.title}
              </button>
            ) : (
              <span className="font-medium text-gray-900">{templateAndBlocks?.template.title}</span>
            )}{' '}
            meeting with {meeting.client.name}
            {/* on{' '}
            <span className="font-medium text-gray-900">
              {getFormattedDateString(meeting.dateTime)}
            </span>{' '}
            at{' '}
            <span className="font-medium text-gray-900">
              {getFormattedTimeString(meeting.dateTime)}
            </span> */}
          </p>
          <p
            id="justify-end"
            className="flex justify-end items-center leading-5 text-right flex-none text-xs text-gray-500 items-center align-items"
          >
            <time dateTime={String(meeting.dateTime)}>
              {getFormattedDateString(meeting.dateTime)}
            </time>{' '}
            &nbsp;at&nbsp;
            <time dateTime={String(meeting.dateTime)}>
              {getFormattedTimeString(meeting.dateTime)}
            </time>
          </p>
        </div>
        {meeting.location && (
          <div className="flex text-xs text-gray-400">
            <MapPinIcon className="h-4 w-4 mr-1" />
            {meeting.location}
          </div>
        )}
        {/* If error retrieving template data */}
        {templateError && <ErrorBanner message={templateError.message} />}
      </div>
    </li>
  );
}
