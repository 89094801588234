// ---------------------------------------------------------
// For wrapping all pages that are part of the product tour
// ---------------------------------------------------------

import { useEffect } from 'react';
import Joyride, { CallBackProps } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { useProductTourContext } from '../context/ProductTourContext';
import { steps } from '../data/productTourSteps';
import CustomTooltip from './CustomJoyrideTooltip';

export default function ProductTourWrapper({ children }: { children: React.ReactNode }) {
  const { setState, state } = useProductTourContext();
  const navigate = useNavigate();

  // Initialize the tour
  useEffect(() => {
    setState({ ...state, steps, tourActive: true, run: true });
  }, []);

  const handleCallback = (data: CallBackProps) => {
    const { action, index, step, type } = data;
    const next = step.data?.next;
    const previous = step.data?.previous;

    // If user clicks a button to go to next step, update the
    // step index and navigate to the proper page, if relevant
    if (type === 'step:after') {
      console.log(action, step);
      setState({ ...state, stepIndex: action === 'prev' ? index - 1 : index + 1 });
      navigate(action === 'prev' && previous ? previous : next);
    }
  };

  return (
    <>
      {children}
      <Joyride
        callback={handleCallback}
        continuous={true}
        locale={{
          back: 'Back', // Customize "Back" button label
          close: 'Close', // Customize "Close" button label
          last: 'Finish', // Customize label for the last button (instead of "Next")
          next: 'Next', // Customize "Next" button label
          skip: 'Skip Tutorial' // Customize "Skip" button label
        }}
        run={false}
        // showProgress={true}
        stepIndex={state.stepIndex}
        steps={steps}
        tooltipComponent={CustomTooltip}
      />
    </>
  );
}
