import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import useNavigation from '../common/navigation';
import ProductTourWrapper from '../components/ProductTourWrapper';
import Sidebar from '../components/Sidebar';
import RecordAudioButton from '../RecordAudioButton';

// const stats = [
//   { name: 'Offices worldwide', value: '12' },
//   { name: 'Full-time colleagues', value: '300+' },
//   { name: 'Hours per week', value: '40' },
//   { name: 'Paid time off', value: 'Unlimited' }
// ];

export default function HomePage() {
  const { instance } = useMsal();
  const activeAccount: AccountInfo | null = instance.getActiveAccount();
  const tokenClaims = activeAccount?.idTokenClaims;
  let activeUserName = 'unknown';
  if (tokenClaims) {
    activeUserName =
      (tokenClaims['given_name'] as string) + ' ' + (tokenClaims['family_name'] as string) || '';
  }

  const { goToClientsPage, goToNotesPage, goToTemplatesPage } = useNavigation();
  const links = [
    { name: 'Your Clients', id: 'home-clients-link', navigation: goToClientsPage },
    { name: 'Your Meeting Notes', id: 'home-notes-link', navigation: goToNotesPage },
    { name: 'Your Meeting Templates', id: 'home-templates-link', navigation: goToTemplatesPage }
  ];

  return (
    <ProductTourWrapper>
      <Sidebar currentPageName={'Home'} />
      <main className="flex-1 flex items-center lg:ml-72 flex h-screen">
        <div className=" max-w-7xl px-6 lg:px-8 ">
          {/*mx-auto*/}
          <div className="-mt-32 max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-semibold tracking-tight text-gray-700 sm:text-5xl">
              Welcome Back, <span className="font-bold bg-indigo-50">{activeUserName}</span>
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-500">
              Simplify your workflow with AI-powered note automation. Build custom meeting
              templates, access client information, and streamline paperwork, so you can focus on
              what truly matters.
            </p>
          </div>
          <div id="linkContainer" className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7  sm:grid-cols-2 md:flex lg:gap-x-10">
              {links.map((link) => (
                <button
                  className="rounded bg-indigo-50 hover:bg-indigo-500 px-6 py-1 text-indigo-800 hover:text-white transition-color duration-300"
                  key={link.name}
                  onClick={link.navigation}
                  id={link.id}
                >
                  {link.name} <span aria-hidden="true">&rarr;</span>
                </button>
              ))}
            </div>
            {/* <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.name} className="flex flex-col-reverse">
                  <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                  <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl> */}
          </div>
        </div>
        <RecordAudioButton />
      </main>
    </ProductTourWrapper>
  );
}
