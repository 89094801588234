import { Dialog, Transition } from '@headlessui/react';
import { UseMutationResult } from '@tanstack/react-query';
import { Fragment } from 'react';
import { Client } from '../common/types';
import ErrorBanner from './ErrorBanner';
import { LoadingIcon } from './LoadingIcon';

const MAX_CHARS = 35;

interface AddMeetingModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  createClientMutation: UseMutationResult<Client, Error, string, unknown>;
  firstName: string;
  lastName: string;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  submitClient: (e: React.FormEvent<HTMLFormElement>) => void;
  isSubmitting: boolean;
}

export default function AddClientModal({
  isOpen,
  setIsOpen,
  createClientMutation,
  firstName,
  lastName,
  setFirstName,
  setLastName,
  submitClient,
  isSubmitting
}: AddMeetingModalProps) {
  // Trim whitespace and remove any punctuation or numbers
  const sanitizeName = (input: string) => {
    return input.replace(/[0-9!@#$%^&*()_+=[\]{};':"\\|<>/?`~]/g, '');
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          setIsOpen(false);
          createClientMutation.reset();
        }}
      >
        {/* Grayed-out background */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Core Modal */}
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                id="add-client-modal"
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-md sm:p-12"
              >
                <div>
                  <div className="text-center">
                    {/* Modal title */}
                    <Dialog.Title as="h3" className="text-xl font-bold leading-6 text-gray-900">
                      Add Client
                    </Dialog.Title>

                    {/* Error creating client */}
                    {createClientMutation.error && (
                      <ErrorBanner message={createClientMutation.error.message} />
                    )}

                    {/* Modal body */}
                    <div className="mt-8 sm:mx-auto sm:w-full">
                      <form className="space-y-4" onSubmit={submitClient}>
                        <div>
                          <label className="block text-left text-sm font-medium leading-6 text-gray-900">
                            First Name
                          </label>
                          <input
                            id="name"
                            name="name"
                            type="text"
                            minLength={1}
                            maxLength={MAX_CHARS}
                            onChange={(e) => setFirstName(sanitizeName(e.target.value))}
                            value={firstName}
                            required
                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {/* Character Count Indicator */}
                          <div className="text-right justify-right text-xs text-gray-500 mt-1">
                            {firstName.length}/{MAX_CHARS} characters
                          </div>
                        </div>

                        <div>
                          <label className="block text-left text-sm font-medium leading-6 text-gray-900">
                            Last Name
                          </label>
                          <input
                            id="name"
                            name="name"
                            type="text"
                            minLength={1}
                            maxLength={MAX_CHARS}
                            onChange={(e) => setLastName(sanitizeName(e.target.value))}
                            value={lastName}
                            required
                            className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          <div className="text-right justify-right text-xs text-gray-500 mt-1">
                            {lastName.length}/{MAX_CHARS} characters
                          </div>
                        </div>

                        <div>
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 mt-12 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 disabled:bg-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Add Client
                            {isSubmitting && <LoadingIcon className="ml-2" />}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
