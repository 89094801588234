import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalContext } from '../context/GlobalProvider';

export default function Toastify() {
  const { state, setState } = useGlobalContext();

  const showSuccessToast = (message: string) => {
    toast.success(message, {
      progressStyle: { background: '#14b8a6' },
      icon: <CheckCircleIcon className="text-teal-500" />
    });
  };

  const showErrorToast = (message: string) => {
    toast.error(message, {
      icon: <ExclamationTriangleIcon className="text-red-600" aria-hidden="true" />
    });
  };

  const showInfoToast = (message: string) => {
    toast.info(message, {
      progressStyle: { background: '#14b8a6' },
      icon: <CheckCircleIcon className="text-teal-500" />
    });
  };

  useEffect(() => {
    if (state.isDoneProcessing) {
      showSuccessToast('Processing completed');
      setState({ ...state, isDoneProcessing: false });
    }
    if (state.hasStartedProcessing) {
      showInfoToast(
        'Recording uploaded successfully. It is now being processed - this usually takes around 2 to 5 minutes.'
      );
      setState({ ...state, hasStartedProcessing: false });
    }
    if (state.templateDeletionError) {
      showErrorToast('Template deletion failed. Please try again.');
      setState({ ...state, templateDeletionError: false });
    }
    if (state.processingError) {
      showErrorToast('Processing failed. Please try again.');
      setState({ ...state, processingError: false });
    }
    if (state.templateDuplicationError) {
      showErrorToast('Template duplication failed. Please try again.');
      setState({ ...state, templateDuplicationError: false });
    }
    if (state.templateUpdateError) {
      showErrorToast('Template update failed. Please try again.');
      setState({ ...state, templateUpdateError: false });
    }
    if (state.templateBuilderError) {
      showErrorToast(
        'Error saving changes. Your recent updates may not have been saved. Please refresh the page and try again.'
      );
      setState({ ...state, templateBuilderError: false });
    }
    if (state.templateCreationError) {
      showErrorToast('Error creating template. Please refresh the page and try again.');
      setState({ ...state, templateCreationError: false });
    }
    if (state.meetingCreationSuccess) {
      showSuccessToast('Meeting created successfully');
      setState({ ...state, meetingCreationSuccess: false });
    }
    if (state.meetingCreationError) {
      showErrorToast('Meeting creation failed. Please refresh the page and try again.');
      setState({ ...state, meetingCreationError: false });
    }
    if (state.meetingDeletionSuccess) {
      showSuccessToast('Meeting deleted successfully');
      setState({ ...state, meetingDeletionSuccess: false });
    }
    if (state.meetingDeletionError) {
      showErrorToast('Meeting deletion failed. Please refresh the page and try again.');
      setState({ ...state, meetingDeletionError: false });
    }
    if (state.templateDeletionSuccess) {
      showSuccessToast('Template deleted successfully');
      setState({ ...state, templateDeletionSuccess: false });
    }
    if (state.clientCreationSuccess) {
      showSuccessToast('Client created successfully');
      setState({ ...state, clientCreationSuccess: false });
    }
    if (state.clientCreationError) {
      showErrorToast('Client creation failed. Please refresh the page and try again.');
      setState({ ...state, clientCreationError: false });
    }
    if (state.shareNotesSuccess) {
      showSuccessToast('Notes sent successfully');
      setState({ ...state, shareNotesSuccess: false });
    }
    if (state.shareNotesError) {
      showErrorToast('Error sending notes. Please refresh the page and try again.');
      setState({ ...state, shareNotesError: false });
    }
    if (state.createCustomQuestionError) {
      showErrorToast('Error saving question to notes. Please refresh the page and try again.');
      setState({ ...state, createCustomQuestionError: false });
    }
    if (state.createCustomQuestionSuccess) {
      showSuccessToast('Question saved to notes successfully');
      setState({ ...state, createCustomQuestionSuccess: false });
    }
    if (state.processCustomQuestionError) {
      showErrorToast('Error generating answer to question. Please try again.');
      setState({ ...state, processCustomQuestionError: false });
    }
    if (state.deleteCustomQuestionSuccess) {
      showSuccessToast('Question deleted successfully');
      setState({ ...state, deleteCustomQuestionSuccess: false });
    }
    if (state.deleteCustomQuestionError) {
      showErrorToast('Error deleting question. Please refresh the page and try again.');
      setState({ ...state, deleteCustomQuestionError: false });
    }
  }, [
    state.isDoneProcessing,
    state.hasStartedProcessing,
    state.templateDeletionError,
    state.processingError,
    state.templateDuplicationError,
    state.templateUpdateError,
    state.templateBuilderError,
    state.templateCreationError,
    state.meetingDeletionSuccess,
    state.meetingDeletionError,
    state.templateDeletionSuccess,
    state.meetingCreationSuccess,
    state.meetingCreationError,
    state.clientCreationSuccess,
    state.clientCreationError,
    state.shareNotesSuccess,
    state.shareNotesError,
    state.createCustomQuestionError,
    state.createCustomQuestionSuccess,
    state.processCustomQuestionError,
    state.deleteCustomQuestionSuccess,
    state.deleteCustomQuestionError
  ]);

  return <ToastContainer autoClose={7000} />;
}
