import { useEffect } from 'react';
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer';

// Create filename with date and time
const fileName = () => {
  const now = new Date();
  const date = now.toISOString().split('T')[0]; // Get date part (YYYY-MM-DD)
  const time = now.toTimeString().split(' ')[0].split(':'); // Get time part (HH-MM)
  const timeString = `${time[0]}-${time[1]}`; // Combine hours and minutes
  return `notewell_recorded_audio_${date}_${timeString}`;
};

const AudioRecorder = ({
  setFile,
  handleAudio,
  clearAudio,
  isDownloadAudioButtonShown
}: {
  setFile: (file: File | null) => void;
  handleAudio: () => void;
  clearAudio: boolean; // If true, clear the audio so it doesn't display "Changes you made may not be saved." alert
  isDownloadAudioButtonShown: boolean;
}) => {
  // Initialize the recorder controls using the hook
  const recorderControls = useVoiceVisualizer({
    onStopRecording: () => {
      handleAudio();
    }
  });
  const {
    // ... (Extracted controls and states, if necessary)
    recordedBlob,
    error
  } = recorderControls;

  // Get the recorded audio blob
  useEffect(() => {
    if (!recordedBlob) {
      setFile(null);
    } else {
      // Convert into file
      const file = new File([recordedBlob], fileName(), {
        type: recordedBlob.type,
        lastModified: Date.now()
      });
      setFile(file);
    }
  }, [recordedBlob, error]);

  useEffect(() => {
    if (clearAudio) recorderControls.clearCanvas(); // Clear the audio source
  }, [clearAudio]);

  // Get the error when it occurs
  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  return (
    <VoiceVisualizer
      controls={recorderControls}
      isProgressIndicatorShown={true}
      mainBarColor={'#999'}
      secondaryBarColor={'#ccc'}
      isDefaultUIShown={false}
      isDownloadAudioButtonShown={isDownloadAudioButtonShown}
      height={150}
      controlButtonsClassName={'voice-visualizer__control-buttons'}
    />
  );
};

export default AudioRecorder;
