import {
  OptArgs,
  TemplateElementKey,
  TemplateElements,
  TemplateElementsWithLength,
  TemplateElementsWithOptions
} from '../common/types';
import { classNames, getReadableString } from '../common/utils';
import useAutoResizeTextarea from '../hooks/useAutoResizeTextarea';
import LengthofList from './LengthofList';
import { LoadingIcon } from './LoadingIcon';
import OptionList from './OptionList';

export default function CustomQuestionBuilder({
  question,
  type,
  optArgs,
  setQuestion,
  handleSetLength,
  handleSetOptions,
  handleGenerateAnswer,
  handleSelectChange,
  isMutationPending
}: {
  question: string;
  type: TemplateElementKey;
  optArgs?: OptArgs | null;
  setQuestion: (question: string) => void;
  handleSetLength: (lengthOfList: number) => void;
  handleSetOptions: (options: string[]) => void;
  handleGenerateAnswer: (e: React.FormEvent<HTMLFormElement>) => void;
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isMutationPending: boolean;
}) {
  const { textareaRef, rows } = useAutoResizeTextarea(question);
  return (
    <form id="customQuestionForm" onSubmit={handleGenerateAnswer}>
      <div className="text-sm mt-2 flex items-center">
        {/* <p className="font-medium">Question Type</p> */}
        {/* Dropdown to select question type */}
        <select
          className="bg-transparent mt-2 inline-block rounded-xl font-medium px-2 py-1 text-indigo-800 border border-indigo-300"
          value={type}
          onChange={handleSelectChange}
          required
        >
          <option disabled value="">
            Select a question type
          </option>
          {Object.keys(TemplateElements).map((key, index) => {
            const templateElementType = TemplateElements[key as TemplateElementKey];
            return (
              <option key={index} value={key}>
                {getReadableString(templateElementType)}
              </option>
            );
          })}
        </select>
      </div>
      <div className="text-sm mt-4">
        {/* <p className="font-medium">Question</p> */}
        <textarea
          ref={textareaRef}
          rows={rows}
          className={classNames(
            'w-full text-base pb-1 pl-1 pr-8 mt-1 outline-none border-b border-indigo-300 focus:border-indigo-500 placeholder:text-indigo-300 transition-border duration-300 bg-transparent'
          )}
          placeholder="Question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          required
        />
      </div>

      <div className="mt-2 !text-sm font-medium">
        {/* Option List for Elements that need it */}
        {type && TemplateElementsWithOptions.includes(TemplateElements[type]) && (
          <OptionList
            options={optArgs?.options || ['']}
            setOptions={(options: string[]) => handleSetOptions(options)}
            isReadOnly={false}
          />
        )}

        {/* Length of list input for elements that need it */}
        {type && TemplateElementsWithLength.includes(TemplateElements[type]) && (
          <LengthofList
            lengthOfList={optArgs?.lengthOfList || 3}
            onChange={(lengthOfList: number) => handleSetLength(lengthOfList)}
            isReadOnly={false}
          />
        )}
      </div>
      <div className="flex w-full items-center justify-center mt-4">
        <button
          disabled={isMutationPending}
          form="customQuestionForm"
          className="inline-flex items-center text-sm px-3 py-1.5 rounded-md font-semibold mt-2 bg-indigo-600 hover:bg-indigo-500 text-white transition-color duration-300 disabled:cursor-not-allowed disabled:bg-indigo-300"
          type="submit"
        >
          {isMutationPending ? 'Asking...' : 'Ask'}
          {isMutationPending && <LoadingIcon className="ml-2 inline-block" />}
        </button>
      </div>
    </form>
  );
}
