import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

export default function NoteSectionApprovedAlert({ open }: { open: boolean }) {
  return (
    <Transition
      enter="transition ease duration-500 transform"
      enterFrom="opacity-0 -translate-y-12"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease duration-300 transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-12"
      show={open}
    >
      <div className="fixed z-20 top-12 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-md border border-green-100 bg-green-50 p-4 fixed align-middle px-5 py-3 h-11 w-64">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">Note section approved</h3>
          </div>
        </div>
      </div>
    </Transition>
  );
}
