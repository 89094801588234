import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { msalConfig } from './authconfig';
import { createUser, getCurrentUser } from './clients/userClient';
import { User } from './common/types';
import ErrorBoundary from './components/ErrorBoundary';
import Toastify from './components/Toastify';
import { GlobalProvider } from './context/GlobalProvider';
import { ProductTourProvider } from './context/ProductTourContext';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Set the base URL for the API
const environment = process.env.REACT_APP_NODE_ENV || 'dev';

export const apiBaseUrl =
  environment === 'prod'
    ? 'https://apim-notewell-prod-wus.azure-api.net/api/'
    : process.env.REACT_APP_NODE_ENV === 'staging'
      ? 'https://appcs-backend-dev-wus-001.azurewebsites.net'
      : 'http://localhost:8080';

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().then(() => {
  // Successful login
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      // Check if user exists, if not create user
      getCurrentUser()
        .then((_: User) => {})
        .catch((_: Error) => {
          // @TODO: Define custom error codes for this, but for now error=404
          const tokenClaims = account?.idTokenClaims;
          if (tokenClaims) {
            const name: string =
              (tokenClaims['given_name'] as string) +
                ' ' +
                (tokenClaims['family_name'] as string) || '';
            if (tokenClaims['emails']) {
              const email: string = tokenClaims['emails'][0] || '';
              createUser(name, email);
            } else {
              createUser(name, ' ');
            }
          } else {
            throw new Error('Failed to create user. No account name found.');
          }
        });
    }
    // Successful logout
    if (event.eventType === EventType.LOGOUT_SUCCESS) {
      msalInstance.setActiveAccount(null);
    }
  });
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false } } });

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <ProductTourProvider>
            <Toastify /> {/* For global notifications */}
            <App pca={msalInstance} />
          </ProductTourProvider>
        </GlobalProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
