import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const authRequest = {
    scopes: ['openid', 'offline_access']
  };
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <Outlet />
    </MsalAuthenticationTemplate>
  );
};

export default PrivateRoute;
