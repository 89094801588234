import meetingData from '../../json/meetingData.json';
import SourceRequestButton from '../SourceRequestButton';

const progressUpdates = [
  {
    question: 'Did Client face any challenges with assigned tasks? If so, what?',
    answer: 'No challenges mentioned.',
    rows: 1
  },
  {
    question:
      'Did Client need further support to complete any of the tasks? If so, what support was needed? What support was provided?',
    answer:
      'Yes, the client expressed a need for support in regaining custody of their children and finding stable housing. The support provided included information about a housing program tailored to assist families in securing housing and financial assistance.',
    rows: 3
  },
  {
    question: 'Did Client experience new successes or progress? If so, what?',
    answer: 'No new successes or progress mentioned.',
    rows: 1
  },
  {
    question: 'Any changes in contact information?',
    answer: 'No changes in contact information mentioned.',
    rows: 1
  },
  {
    question: 'Any changes in housing status?',
    answer: 'No changes in housing status mentioned.',
    rows: 1
  },
  {
    question: 'Any other updates or changes?',
    answer: 'Yes, the client faced a new challenge related to losing custody of their children.',
    rows: 1
  }
];

const meetingThemes = [
  {
    theme: 'Housing Instability and Challenges',
    description:
      "The conversation predominantly revolves around the client's housing instability, challenges faced in maintaining housing, and the assistance offered through the housing program."
  },
  {
    theme: 'Family Reunification and Child Custody',
    description:
      "There is a significant focus on the client's desire to regain custody of their children and the steps needed to reunite the family. Discussions include the client's past issues with alcohol abuse and its impact on their ability to care for their children."
  },
  {
    theme: 'Support Services and Program Participation',
    description:
      'The meeting emphasizes the support services available through the housing program, including assistance with finding housing, financial literacy, and rental assistance. The client expresses interest in participating in the program to address their housing needs and regain stability.'
  }
];

const topBarriers = [
  {
    barrier: 'Housing Instability',
    description:
      'The client has been homeless for the past month and is struggling to secure stable housing due to financial constraints and lack of family support.',
    sourceIndices: [6, 24, 25, 31, 32, 33]
  },
  {
    barrier: 'Substance Abuse',
    description:
      "The client's increased drinking over the past six months has contributed to his current situation, including homelessness and loss of custody of his children.",
    sourceIndices: [9, 35, 41, 42]
  },
  {
    barrier: 'Mental Health',
    description:
      "The client admits to feeling depressed and expresses a desire to address his mental health. Mental health issues can significantly impact the client's ability to cope with challenges and engage effectively in addressing other barriers.",
    sourceIndices: [39, 41]
  }
];
const referrals = [
  {
    agency: 'Napa Valley Community Housing',
    reason: 'Client is encountering housing instability',
    accepted: true
  },
  {
    agency: 'Napa County Mental Health Services',
    reason: 'Client expressed a desire to address mental health concerns',
    accepted: true
  },
  {
    agency: 'Napa County Substance Use Services',
    reason: "Client's increased drinking has contributed to current situation",
    accepted: false
  }
];

export default function NoteSectionBodyMeeting({
  handleSourceRequest
}: {
  handleSourceRequest: (sourceIndices: number[]) => void;
}) {
  return (
    <div className="p-5">
      <ol className="list-decimal space-y-10">
        {/* Progress and Updates */}
        <li>
          <label htmlFor="comment" className="text-base font-semibold leading-6">
            Progress & Updates
          </label>
          <ol className="list-[lower-alpha] ml-5 my-2 space-y-3">
            {progressUpdates.map((update) => (
              <li>
                <label className="text-gray-900">{update.question}</label>
                <div className="flex">
                  <textarea
                    rows={update.rows}
                    className="mt-1 sm:mt-0 py-1.5 sm:px-3 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6 w-full"
                    defaultValue={update.answer}
                  />
                  <SourceRequestButton onClick={() => handleSourceRequest([1, 2, 3])} />
                </div>
              </li>
            ))}
          </ol>
        </li>

        {/* Themes */}
        <li>
          <label
            htmlFor="comment"
            className="text-base block font-semibold leading-6 text-gray-900 mb-2"
          >
            Themes
          </label>
          <label htmlFor="comment" className="text-base leading-6 text-gray-900">
            The primary themes identified during the meeting were:
          </label>
          <ul className="list-[lower-alpha] ml-5 mt-1 space-y-2">
            {meetingThemes.map((meetingTheme) => (
              <li>
                <div className="flex">
                  <input
                    type="text"
                    className="mt-1 sm:mt-0 py-1.5 sm:px-3 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6 w-full"
                    defaultValue={meetingTheme.theme}
                  />
                  <SourceRequestButton onClick={() => handleSourceRequest([1, 2, 3])} />
                </div>
              </li>
            ))}
          </ul>
        </li>

        {/* Barriers */}
        <li>
          <label
            htmlFor="comment"
            className="text-base block font-semibold leading-6 text-gray-900 mb-2"
          >
            Barriers Addressed
          </label>
          <label htmlFor="comment" className="text-base leading-6 text-gray-900">
            The following self-identified barriers were addressed in the meeting with Client:
          </label>
          <ul className="list-[lower-alpha] ml-5 mt-1 space-y-2">
            {topBarriers.map((barrier) => (
              <li>
                <label>Barrier:</label>
                <select className="select sm:mt-0 py-1.5 text-gray-600 sm:px-3 focus:text-gray-900 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6">
                  <option disabled selected>
                    {barrier.barrier}
                  </option>
                  {Object.keys(meetingData.barrierData).map((barrier) => (
                    <option>{barrier}</option>
                  ))}
                </select>
                <label className="mt-2 mb-1 block">How it was addressed:</label>
                <div className="flex">
                  <textarea
                    rows={3}
                    className="mt-1 sm:mt-0 py-1.5 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6 w-full"
                    // className="mt-10 rounded-lg sm:mt-0 py-1.5 sm:px-3 text-gray-600 focus:text-gray-900 border outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6 w-full"
                    defaultValue={barrier.description}
                  />
                  <SourceRequestButton onClick={() => handleSourceRequest(barrier.sourceIndices)} />
                </div>
              </li>
            ))}
          </ul>
        </li>

        {/* Referrals */}
        <li>
          <label
            htmlFor="comment"
            className="text-base block font-semibold leading-6 text-gray-900 mb-2"
          >
            Referrals
          </label>
          <label htmlFor="comment" className="text-base leading-6 text-gray-900">
            Worker discussed the following referrals with Client:
          </label>
          <table className="table-auto rounded-lg w-full mt-2 divide-y divide-gray-300 border border-gray-300">
            <thead className="py-3.5 pl-4 pr-3 text-left text-sm">
              <tr className="divide-x">
                <th className="p-3">Referral</th>
                <th className="p-3">Referral Reason</th>
                <th className="p-3">Status</th>
                <th className="p-3"></th>
              </tr>
              <tr></tr>
              <tr></tr>
            </thead>
            <tbody className="px-3 py-4 text-sm text-gray-700 divide-y divide-gray-300">
              {referrals.map((referral) => (
                <tr className="p-3 divide-x">
                  <td className="w-[35%] p-3">
                    <input type="text" defaultValue={referral.agency} className="w-full" />
                  </td>
                  <td className="w-[45%] p-3">
                    <textarea defaultValue={referral.reason} className="w-full py-2" />
                  </td>
                  <td className="w-[10%] p-3">
                    <select>
                      <option selected={referral.accepted}>Accepted</option>
                      <option selected={!referral.accepted}>Denied</option>
                    </select>
                  </td>
                  <td className="pr-3">
                    <SourceRequestButton onClick={() => handleSourceRequest([1, 2, 3])} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </li>
      </ol>

      {/* <h2>Barrier Analysis</h2>
      <ul>
        {Object.entries(meetingData.formSections.barrierData).map(([barrier, data]) => (
          <li key={barrier}>
            <strong>{barrier}</strong>:
            <ul>
              <li>Identified: {data.Identified ? 'Yes' : 'No'}</li>
              <li>Reason: {data.Reason}</li>
            </ul>
          </li>
        ))}
      </ul> */}
    </div>
  );
}
