import { BasicMeetingDataOld } from '../pages/NotePage-old';

interface BasicMeetingDataSectionProps {
  basicMeetingData: BasicMeetingDataOld;
  setBasicMeetingData: (basicMeetingData: BasicMeetingDataOld) => void;
}

export default function BasicMeetingDataSection({
  basicMeetingData,
  setBasicMeetingData
}: BasicMeetingDataSectionProps) {
  return (
    <div className="mx-auto max-w-7xl py-4 sm:px-6 lg:px-8 mt-2">
      {Object.entries(basicMeetingData).map(([label, value]) => (
        <div
          key={label}
          className="px-4 py-3 sm:py-2.5 sm:grid sm:grid-cols-[20%_80%] sm:gap-4 sm:px-0 items-center"
        >
          <label className="text-sm flex font-medium capitalize leading-6 text-gray-900 col-span-2 sm:col-span-1">
            {label}
          </label>
          <input
            type="text"
            name={label.replace(/\s+/g, '-')}
            id={label.replace(/\s+/g, '-')}
            className="col-span-2 sm:col-span-1 mt-1 sm:mt-0 py-1.5 sm:px-3 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-200 focus:border-indigo-600 placeholder:text-gray-400 sm:text-base sm:leading-6 w-full"
            value={value}
            onChange={(e) => setBasicMeetingData({ ...basicMeetingData, [label]: e.target.value })}
          />
        </div>
      ))}
    </div>
  );
}
