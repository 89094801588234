import { TrashIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { ReactNode, useEffect } from 'react';
import { getSourceCitationIndices } from '../clients/meetingClient';
import {
  AnswerValue,
  OptionsAnswer,
  TemplateBlock,
  TemplateBlockAnswer,
  TemplateElements
} from '../common/types';
import ErrorBanner from './ErrorBanner';
import TemplateBlockContentDate from './TemplateBlockContentDate';
import TemplateBlockContentMultiSelect from './TemplateBlockContentMultiSelect';
import TemplateBlockContentParagraph from './TemplateBlockContentParagraph';
import TemplateBlockContentShortAnswer from './TemplateBlockContentShortAnswer';
import TemplateBlockContentShortAnswerList from './TemplateBlockContentShortAnswerList';
import TemplateBlockContentSingleSelect from './TemplateBlockContentSingleSelect';
import TemplateBlockContentTime from './TemplateBlockContentTime';

interface TemplateBlockStructureProps {
  meetingId: number;
  block: TemplateBlock;
  answer?: TemplateBlockAnswer;
  displaySourceIndices: (sourceIndices?: number[]) => void;
  handleBlockAnswerUpdate: (blockId: number, newAnswerValue: AnswerValue) => void;
  isCustomQuestion: boolean;
  handleTrash: (blockId: number, answerId?: number) => void;
}

const getTemplateBlockContent = (
  block: TemplateBlock,
  answer: TemplateBlockAnswer,
  handleBlockAnswerUpdate: (blockId: number, updatedAnswer: AnswerValue) => void
): ReactNode => {
  switch (block.type) {
    case TemplateElements.SingleSelect:
      return (
        <TemplateBlockContentSingleSelect
          blockId={block.id}
          answer={answer.answer as OptionsAnswer}
          handleBlockAnswerUpdate={handleBlockAnswerUpdate}
        />
      );
    case TemplateElements.MultiSelect:
      return (
        <TemplateBlockContentMultiSelect
          blockId={block.id}
          answer={answer.answer as OptionsAnswer}
          handleBlockAnswerUpdate={handleBlockAnswerUpdate}
        />
      );
    case TemplateElements.ShortAnswer:
      return (
        <TemplateBlockContentShortAnswer
          blockId={block.id}
          answer={answer.answer as string}
          handleBlockAnswerUpdate={handleBlockAnswerUpdate}
        />
      );
    case TemplateElements.ShortAnswerList:
      return (
        <TemplateBlockContentShortAnswerList
          blockId={block.id}
          answer={answer.answer as string[]}
          handleBlockAnswerUpdate={handleBlockAnswerUpdate}
        />
      );
    case TemplateElements.Paragraph:
      return (
        <TemplateBlockContentParagraph
          blockId={block.id}
          answer={answer.answer as string}
          handleBlockAnswerUpdate={handleBlockAnswerUpdate}
        />
      );
    case TemplateElements.Date:
      return (
        <TemplateBlockContentDate
          blockId={block.id}
          answer={answer.answer as Date}
          handleBlockAnswerUpdate={handleBlockAnswerUpdate}
        />
      );
    case TemplateElements.Time:
      return (
        <TemplateBlockContentTime
          blockId={block.id}
          answer={answer.answer as Date}
          handleBlockAnswerUpdate={handleBlockAnswerUpdate}
        />
      );
  }
};

export default function TemplateBlockStructure({
  meetingId,
  block,
  answer,
  handleBlockAnswerUpdate,
  displaySourceIndices,
  isCustomQuestion,
  handleTrash
}: TemplateBlockStructureProps) {
  // Query for retrieving source citation indices upon request
  const {
    data: sourceCitationIndices,
    error,
    refetch
  } = useQuery({
    queryKey: ['getSourceCitationIndices', meetingId, block.question, answer?.answer],
    queryFn: () => {
      const query = block.question + JSON.stringify(answer?.answer);
      return getSourceCitationIndices(meetingId, query);
    },
    enabled: false // only run query on button click
  });

  const isQuestionEmpty = block.question.trim() === '';

  // Render indices once data is loaded
  useEffect(() => {
    if (sourceCitationIndices) {
      displaySourceIndices(sourceCitationIndices);
    }
  }, [sourceCitationIndices]);

  return (
    <li className="mb-7">
      <label className="text-gray-700 overflow-wrap flex text-md sm:mt-0 pb-2 font-medium bg-transparent w-fit">
        {isQuestionEmpty
          ? 'This question was left blank in the template, so an answer was not generated.'
          : block.question}
        {isCustomQuestion && (
          <div className="flex items-center">
            <div className="relative group">
              <span className="ml-2">✨</span>

              {/* Tooltip */}
              <span className="absolute z-40 block bottom-full bg-gray-500 left-7 top-0 h-fit w-64 text-white px-2 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                This is a follow-up question that was added to your meeting notes using the question
                box below.
              </span>
            </div>

            <button
              className="ml-2 text-xs text-gray-400 hover:text-gray-900 font-semibold transition-color duration-300"
              onClick={() => handleTrash(block.id, answer ? answer.id : undefined)}
            >
              <TrashIcon className="h-3.5 w-3.5" />
            </button>
          </div>
        )}
      </label>
      <div className="flex">
        {answer ? (
          !isQuestionEmpty && getTemplateBlockContent(block, answer, handleBlockAnswerUpdate)
        ) : (
          <p className="text-gray-600 text-sm italic">
            An answer was not generated for this question.
          </p>
        )}
        {/* TODO: reinstate source request button! */}
        {/*{!isQuestionEmpty && <SourceRequestButton onClick={() => refetch()} /> }*/}
      </div>
      {/* Error if source request fails */}
      {error && <ErrorBanner message={error.message} />}
    </li>
  );
}
