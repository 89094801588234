import { useEffect, useRef, useState } from 'react';

const useAutoResizeTextarea = (
  text: string
): {
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  rows: number;
} => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [rows, setRows] = useState(1);

  const recalculateRows = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 10);

      // Reset the rows to 1 to calculate based on the scroll height
      textarea.rows = 1;
      const scrollHeight = textarea.scrollHeight;

      const newRows = Math.ceil(scrollHeight / lineHeight) - 1;
      setRows(newRows);
      textarea.rows = newRows;
    }
  };

  useEffect(() => {
    // Initial calculation when the text or window size changes
    recalculateRows();
    const handleResize = () => {
      recalculateRows();
    };
    // Add event listener for window resize, cleanup on unmount
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [text]);

  return { textareaRef, rows };
};

export default useAutoResizeTextarea;
