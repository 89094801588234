import { useEffect, useState } from 'react';

const useDebounce = <T>(object: T, delay: number): T => {
  const [debouncedObject, setDebouncedObject] = useState<T>(object);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedObject(object);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [object, delay]);
  return debouncedObject;
};

export default useDebounce;
