import SourceRequestButton from '../SourceRequestButton';

const actionSteps = [
  {
    action: 'Fill out budget sheet',
    assignee: 'Alex',
    dueDate: '2024-03-25'
  },
  {
    action: 'Find housing options',
    assignee: 'Michael',
    dueDate: '2024-04-01'
  },
  {
    action: 'Submit release of information forms',
    assignee: 'Alex',
    dueDate: '2024-03-30'
  },
  {
    action: 'Research support programs for single parents',
    assignee: 'Michael',
    dueDate: '2024-03-27'
  }
];

export default function NoteSectionBodyNextSteps({
  handleSourceRequest
}: {
  handleSourceRequest: (sourceIndices: number[]) => void;
}) {
  return (
    <div className="border-b-gray p-5">
      <ol className="list-decimal list-outside space-y-10">
        {/* Next Meeting Details */}
        <li>
          <label className="text-base block font-semibold leading-6 text-gray-900 mb-2">
            Next meeting between Worker and Client
          </label>
          <ol className="list-[lower-alpha] space-y-2 ml-5">
            <li>
              <label>Purpose</label>
              <div className="flex">
                <textarea
                  defaultValue="To review the progress made on the action plan developed during the initial meeting and to discuss any additional support or resources needed to overcome barriers and achieve the client's goals."
                  rows={3}
                  className="mt-1 sm:mt-0 py-1.5 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6 w-full"
                />
                <SourceRequestButton onClick={() => handleSourceRequest([4, 8, 9])} />
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <label className="mr-2">Date</label>
                <input
                  type="date"
                  className="text-gray-600 border-b border-gray-300"
                  defaultValue="2024-06-23"
                />
                <SourceRequestButton onClick={() => handleSourceRequest([50, 52])} />
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <label className="mr-2">Time</label>
                <input
                  type="time"
                  className="text-gray-600 border-b border-gray-300"
                  defaultValue="09:30:00"
                />
                <SourceRequestButton onClick={() => handleSourceRequest([52])} />
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <label className="mr-2">Location</label>
                <input
                  type="text"
                  className="col-span-2 sm:col-span-1 w-96 mt-1 sm:mt-0 py-1.5 sm:px-3 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6"
                  defaultValue="Napa Housing Program Office"
                />
                <SourceRequestButton onClick={() => handleSourceRequest([4, 8, 9])} />
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <label className="mr-2">How will Client be reminded?</label>
                <select className="select sm:mt-0 py-1.5 sm:px-3 focus:text-gray-900 text-gray-600 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6">
                  <option selected>E-mail</option>
                  {['Text', 'Call', 'Mail'].map((value) => (
                    <option>{value}</option>
                  ))}
                </select>
                <SourceRequestButton onClick={() => handleSourceRequest([4, 8, 9])} />
              </div>
            </li>
          </ol>
        </li>

        {/* Homework / Action Steps */}
        <li>
          <label className="text-base block font-semibold leading-6 text-gray-900 mb-2">
            Homework / Action Steps
          </label>
          <table className="table-auto rounded-lg w-full mt-2 divide-y divide-gray-300 border border-gray-300">
            <thead className="py-3.5 pl-4 pr-3 text-left text-sm">
              <tr className="divide-x">
                <th className="p-3">Action Step</th>
                <th className="p-3">Assignee</th>
                <th className="p-3">Due Date</th>
                <th className="p-3"></th>
              </tr>
              <tr></tr>
              <tr></tr>
            </thead>
            <tbody className="px-3 py-4 text-sm text-gray-700 divide-y divide-gray-300">
              {actionSteps.map((action) => (
                <tr className="p-3 divide-x">
                  <td className="w-[65%] p-3">
                    <input type="text" defaultValue={action.action} className="w-full" />
                  </td>
                  <td className="w-[20%] p-3">
                    <select>
                      <option selected={action.assignee === 'Alex'}>Alex</option>
                      <option selected={action.assignee === 'Michael'}>Michael</option>
                    </select>
                  </td>
                  <td className="w-[15%] p-3">
                    <input type="date" defaultValue={action.dueDate} className="text-gray-600" />
                  </td>
                  <td className="pr-3">
                    <SourceRequestButton onClick={() => handleSourceRequest([1, 2, 3])} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </li>
      </ol>
    </div>
  );
}
