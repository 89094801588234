import { AnswerValue } from '../common/types';

interface TemplateBlockContentTimeProps {
  blockId: number;
  answer: Date;
  handleBlockAnswerUpdate: (blockId: number, newValue: AnswerValue) => void;
}

// Format dateTime to HH:MM
const formatTimeForInput = (dateTime: Date) => {
  if (!dateTime) return '';
  const date = new Date(dateTime);

  if (isNaN(date.getTime())) {
    console.error('Invalid date format');
    return undefined;
  }

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
};

// Convert HH:MM to Date object
const formatTimeForServer = (time: string) => {
  const [hours, minutes] = time.split(':');
  console.log(hours, minutes);
  return new Date(0, 0, 0, Number(hours), Number(minutes));
};

export default function TemplateBlockContentTime({
  blockId,
  answer,
  handleBlockAnswerUpdate
}: TemplateBlockContentTimeProps) {
  const time = formatTimeForInput(answer);

  return (
    <input
      type="time"
      className="text-gray-600 border-b border-gray-300 focus:border-indigo-700 sm:px-1.5 sm:pb-1.5 text-md bg-transparent"
      defaultValue={time}
      onChange={(e) => handleBlockAnswerUpdate(blockId, formatTimeForServer(e.target.value))}
      required
    />
  );
}
