import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { createMeeting, triggerProcessMeeting } from '../clients/meetingClient';
import useNavigation from '../common/navigation';
import { CreateMeetingRequest, Meeting } from '../common/types';
import AudioRecorder from '../components/AudioRecorder';
import Sidebar from '../components/Sidebar';
import AddMeetingModal from './AddMeetingModal';
import DownloadAudioModal from './DownloadAudioModal';

export default function RecordAudioPage() {
  const [file, setFile] = useState<File | null>();
  const [isDownloadAudioModalOpen, setIsDownloadAudioModalOpen] = useState(false);
  const [isAddMeetingModalOpen, setIsAddMeetingModalOpen] = useState(false);
  const [clearAudio, setClearAudio] = useState(false);

  const { goToNotePage } = useNavigation();

  // Display generate button if user accidentally clicked out of the modal and needs to reopen it
  const isReopenButtonVisible = file && !(isAddMeetingModalOpen || isDownloadAudioModalOpen);

  // Trigger request to create a new meeting. Also clear the audio blob in the audio recorder
  const createMeetingMutation = useMutation({
    mutationFn: (newMeeting: CreateMeetingRequest) => {
      return createMeeting(newMeeting);
    },
    onMutate: () => {
      // Clear the blob in audio recorder so it doesn't display "Changes you made may not be saved." alert
      setClearAudio(true);
    }
  });

  // Trigger request to process the audio file using the new meeting id.
  // Redirect to the meeting notes page
  const processMeetingMutation = useMutation({
    mutationFn: (args: { meetingId: number; file: File }) => {
      return triggerProcessMeeting(args.meetingId, args.file);
    },
    onSuccess: (_, args) => {
      setTimeout(() => {
        goToNotePage(args.meetingId);
      }, 500); // Slight delay to ensure server has time to update meeting processing status
    }
  });

  /**
   * 1. Prevent default form submission
   * 2. Create a new meeting
   * 3. Trigger request to process the audio file using the new meeting id
   */
  const createMeetingAndProcessNotes = async (
    e: React.FormEvent<HTMLFormElement>,
    newMeeting: CreateMeetingRequest
  ) => {
    // Prevent default form submission
    e.preventDefault();
    // Create a new meeting. Wait for this to complete before processing the recording
    createMeetingMutation.mutate(newMeeting, {
      onSuccess: (meeting: Meeting) => {
        if (file) processMeetingMutation.mutate({ meetingId: meeting.id!, file: file });
      }
    });
  };

  const handleContinue = () => {
    setIsDownloadAudioModalOpen(false);
    setIsAddMeetingModalOpen(true);
  };
  const handleGoBack = () => {
    setIsDownloadAudioModalOpen(true);
    setIsAddMeetingModalOpen(false);
  };

  return (
    <div>
      <Sidebar currentPageName={'Meeting Notes'} />
      <DownloadAudioModal
        isOpen={isDownloadAudioModalOpen}
        setIsOpen={setIsDownloadAudioModalOpen}
        handleContinue={handleContinue}
        file={file!}
      />
      <AddMeetingModal
        isOpen={isAddMeetingModalOpen}
        setIsOpen={setIsAddMeetingModalOpen}
        isCurrent={true}
        buttonText={'Generate Meeting Notes'}
        overrideSubmit={createMeetingAndProcessNotes}
        overrideSubmitError={createMeetingMutation.error ?? undefined}
        goBack={handleGoBack}
        createMeetingMutation={createMeetingMutation}
      />
      <main className="lg:ml-72">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 mb-4  lg:w-3/4">
          {/* Heading */}
          <div className="border-b border-gray-200 pt-4 pb-4 mb-8">
            <h3 className="text-3xl font-bold">Record Meeting Audio</h3>
          </div>

          <div className="lg:px-8 pt-8">
            <AudioRecorder
              setFile={setFile}
              handleAudio={() => {
                setIsDownloadAudioModalOpen(true);
              }}
              clearAudio={clearAudio}
              isDownloadAudioButtonShown={false}
            />

            {isReopenButtonVisible && (
              <div className="flex justify-center items-center">
                <button
                  onClick={() => {
                    setIsDownloadAudioModalOpen(true);
                  }}
                  className="mt-4 bg-indigo-600 hover:bg-indigo-400 text-white font-semibold py-2 px-4 rounded text-sm shadow-lg shadow-slate-300"
                >
                  Continue to generate notes
                </button>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
