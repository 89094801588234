import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { createQuestionAndAnswerForNote, processCustomQuestion } from '../clients/meetingClient';
import {
  AnswerValue,
  OptArgs,
  QuestionParentType,
  TemplateBlock,
  TemplateBlockAndAnswer,
  TemplateBlockAnswer,
  TemplateBlockCreate,
  TemplateElements,
  TemplateElementsWithLength,
  TemplateElementsWithOptions
} from '../common/types';
import { classNames } from '../common/utils';
import { useGlobalContext } from '../context/GlobalProvider';
import CustomQuestionBuilder from './CustomQuestionBuilder';
import { LoadingIcon } from './LoadingIcon';
import TemplateBlockStructure from './TemplateBlockStructure';

export default function CustomQuestion({
  meetingId,
  nextIndex,
  className,
  updateCustomQuestionsAndAnswers
}: {
  meetingId: number;
  nextIndex: number;
  className: string;
  updateCustomQuestionsAndAnswers: (newQuestionAndAnswer: TemplateBlockAndAnswer) => void;
}) {
  const [question, setQuestion] = useState('');
  const [type, setType] = useState<TemplateElements>(TemplateElements.Paragraph);
  const [optArgs, setOptArgs] = useState<OptArgs | undefined>();
  const [answer, setAnswer] = useState<TemplateBlockAnswer | undefined>();
  const [templateBlock, setTemplateBlock] = useState<TemplateBlock | undefined>();

  const { setState } = useGlobalContext();

  const processCustomQuestionMutation = useMutation({
    mutationFn: ({ meetingId, question }: { meetingId: number; question: TemplateBlockCreate }) => {
      return processCustomQuestion(meetingId, question);
    },
    onSuccess: (answerValue: AnswerValue, vars) => {
      // Create template block from question data
      const templateBlock: TemplateBlock = {
        id: -1, // placeholder id
        parentType: QuestionParentType.MeetingNote,
        ...vars.question,
        position: nextIndex
      };
      // Create answer from answer data
      const answer: TemplateBlockAnswer = {
        id: -1, // placeholder id
        answer: answerValue,
        meetingId,
        templateBlockId: -1, // placeholder id
        type: vars.question.type
      };
      // on success, display new question and answer using TemplateBlockStructure
      setTemplateBlock(templateBlock);
      setAnswer(answer);
    },
    onError: () => {
      setState((prev) => ({ ...prev, processCustomQuestionError: true }));
    }
  });

  // For persisting a new custom question and answer
  const createCustomQuestionAndAnswerMutation = useMutation({
    mutationFn: ({
      meetingId,
      block,
      answerValue
    }: {
      meetingId: number;
      block: TemplateBlockCreate;
      answerValue: AnswerValue;
    }) => {
      return createQuestionAndAnswerForNote(
        meetingId,
        { ...block, position: nextIndex },
        answerValue
      );
    },
    onSuccess: (newBlockAndAnswer: TemplateBlockAndAnswer) => {
      setState((prev) => ({ ...prev, createCustomQuestionSuccess: true }));
      updateCustomQuestionsAndAnswers(newBlockAndAnswer);
      // Clear state on success
      handleClearQuestion();
    },
    onError: () => {
      setState((prev) => ({ ...prev, createCustomQuestionError: true }));
    }
  });

  const isCustomQuestionSaving = createCustomQuestionAndAnswerMutation.isPending;

  const clearState = () => {
    setQuestion('');
    setType(TemplateElements.Paragraph);
    setOptArgs(undefined);
    setAnswer(undefined);
    setTemplateBlock(undefined);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value as TemplateElements;
    setType(type); // Update state on change

    setOptArgs(undefined); // Reset optArgs
    if (TemplateElementsWithOptions.includes(TemplateElements[type])) {
      setOptArgs({ options: [''] });
    }
    if (TemplateElementsWithLength.includes(TemplateElements[type])) {
      setOptArgs({ lengthOfList: 3 });
    }
  };

  const handleSetOptions = (options: string[]) => {
    setOptArgs((prevOptArgs) => ({ ...prevOptArgs, options }));
  };

  const handleSetLength = (lengthOfList: number) => {
    setOptArgs((prevOptArgs) => ({ ...prevOptArgs, lengthOfList }));
  };

  const handleGenerateAnswer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!type) {
      alert('You must select a question type');
      return;
    }

    const newQuestion: TemplateBlockCreate = {
      position: nextIndex,
      type,
      question,
      optArgs: optArgs ?? undefined
    };
    // Perform mutation to ask a question that does not persist any data
    // Meanwhile, show a loading icon
    // On success, display the new question and answer using TemplateBlockStructure
    processCustomQuestionMutation.mutate({ meetingId, question: newQuestion });
  };

  const handleAnswerUpdate = (_: number, newAnswerValue: AnswerValue) => {
    setAnswer((prevAnswer: TemplateBlockAnswer | undefined) => {
      if (!prevAnswer) return undefined;
      return { ...prevAnswer, answer: newAnswerValue };
    });
  };

  const handleClearQuestion = () => {
    // Clear state
    clearState();

    // Reset mutations
    processCustomQuestionMutation.reset();
  };

  const handleSaveCustomQuestion = (newQuestion: TemplateBlock, newAnswer: TemplateBlockAnswer) => {
    // Perform mutation to save the new question and answer
    createCustomQuestionAndAnswerMutation.mutate({
      meetingId,
      block: newQuestion,
      answerValue: newAnswer.answer
    });
  };

  // save to question list in parent, clear question and answer so they can ask a new question
  const handleSaveQuestion = () => {
    // save question to notes
    if (templateBlock && answer) handleSaveCustomQuestion(templateBlock, answer);
    else {
      alert('Something went wrong. Please try again');
      return;
    }
  };

  return (
    <div
      className={classNames(
        className,
        // 'rounded-lg border border-indigo-300/50 bg-indigo-50/50 py-4 px-6 text-gray-700 bg-gradient-to-br from-blue-50 via-indigo-50 to-fuchsia-50'
        'rounded-lg border border-indigo-300/50 bg-indigo-50/50 py-4 px-6 text-gray-700 bg-gradient-to-br from-blue-50 to-purple-50'
      )}
    >
      {/* If question has been processed and answered, display it as a template block */}
      {processCustomQuestionMutation.isSuccess && templateBlock && answer ? (
        <>
          <ol className="list-none">
            <TemplateBlockStructure
              meetingId={meetingId}
              block={templateBlock}
              answer={answer}
              displaySourceIndices={() => {}}
              handleBlockAnswerUpdate={handleAnswerUpdate}
              isCustomQuestion={false}
              handleTrash={() => {}}
            />
          </ol>
          <button
            className="text-sm bg-indigo-100/80 text-indigo-700 hover:bg-indigo-500 hover:text-white px-3 py-1 rounded-md font-semibold mt-2 transition-color duration-300 shadow"
            onClick={handleClearQuestion}
          >
            <span className="font-semibold">Ask a new question</span>
          </button>
          <button
            disabled={isCustomQuestionSaving}
            onClick={handleSaveQuestion}
            className="ml-4 text-sm text-indigo-700 px-3 py-1 rounded-md font-semibold mt-2 bg-indigo-600 hover:bg-indigo-500 text-white transition-color duration-300 shadow disabled:cursor-not-allowed disabled:bg-indigo-300"
          >
            <span className="font-semibold">
              {isCustomQuestionSaving ? (
                <>
                  Saving... <LoadingIcon className="inline-flex ml-2" />
                </>
              ) : (
                'Save to notes'
              )}
            </span>
          </button>
        </>
      ) : (
        <>
          {/* If question has not been processed, display the form to ask a new question */}
          <div className="flex items-center">
            <h1 className="font-semibold">
              Ask a follow-up question <span className="ml-1">✨</span>
            </h1>
            {/* Tooltip */}
            <div className="text-left relative group inline-flex ml-1">
              <QuestionMarkCircleIcon
                className="h-4 w-4 text-white-800 opacity-30 hover:opacity-70 transition-opacity transition-stroke duration-300"
                aria-hidden="false"
              />
              <span className="absolute block bottom-full bg-gray-500 left-0 top-5 h-fit w-72 text-white px-2 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                <p>
                  If you have follow-up questions about your meeting, you can ask them here. You can
                  also save these to your meeting notes.
                </p>
              </span>
            </div>
          </div>
          <CustomQuestionBuilder
            type={type}
            question={question}
            setQuestion={setQuestion}
            optArgs={optArgs}
            handleSelectChange={handleSelectChange}
            handleSetOptions={handleSetOptions}
            handleSetLength={handleSetLength}
            handleGenerateAnswer={handleGenerateAnswer}
            isMutationPending={processCustomQuestionMutation.isPending}
          />
        </>
      )}
    </div>
  );
}
