import { PlusIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { createClient, getClients } from '../clients/clientClient';
import AddClientModal from '../components/AddClientModal';
import ClientListItem from '../components/ClientListItem';
import EmptyState from '../components/EmptyState';
import ErrorBanner from '../components/ErrorBanner';
import LoadingState from '../components/LoadingState';
import ProductTourWrapper from '../components/ProductTourWrapper';
import Sidebar from '../components/Sidebar';
import { useGlobalContext } from '../context/GlobalProvider';
import { useProductTourContext } from '../context/ProductTourContext';
import { addClientStepIndex, clientsPageStepIndex } from '../data/productTourSteps';
import RecordAudioButton from '../RecordAudioButton';

export default function ClientsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearForm = () => {
    setFirstName('');
    setLastName('');
  };

  const queryClient = useQueryClient();
  const { setState } = useGlobalContext();
  const { setState: setTourState, state: tourState } = useProductTourContext();

  // On mount, if tour is active, trigger the first step for the clients page
  useEffect(() => {
    if (tourState.tourActive) {
      setTourState({ ...tourState, stepIndex: clientsPageStepIndex });
    }
  }, []);

  const moveToNextTourStep = (currentStepIndex: number) => {
    if (tourState.tourActive && tourState.stepIndex === currentStepIndex) {
      setTourState({ ...tourState, stepIndex: currentStepIndex + 1 });
    }
  };

  // Create meeting mutation that is triggered on add client form submission
  const createClientMutation = useMutation({
    mutationFn: (name: string) => {
      return createClient(name);
    },
    onSuccess: () => {
      // Refetch clients to display new client
      queryClient.invalidateQueries({ queryKey: ['getClients'] });

      // Close modal on success
      setIsModalOpen(false);

      // Toast notification
      setState((prevState) => ({
        ...prevState,
        clientCreationSuccess: true
      }));
    },
    onError: () => {
      // Toast notification
      setState((prevState) => ({
        ...prevState,
        clientCreationError: true
      }));
    }
  });

  // Fetch all clients for current user
  const {
    data: clients,
    error: clientsError,
    isLoading: isClientsLoading
  } = useQuery({
    queryKey: ['getClients'],
    queryFn: getClients
  });

  /**
   * On form submission,
   * 1. prevent window from refreshing
   * 2. trigger create meeting mutation with data from form
   */
  const submitClient = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Move to the next product tour step if it's active
    moveToNextTourStep(addClientStepIndex);

    createClientMutation.mutate(`${firstName.trim()} ${lastName.trim()}`, {
      onSuccess: () => {
        clearForm();
        setTimeout(() => {
          setIsSubmitting(false);
        }, 1000);
      },
      onError: () => {
        setIsSubmitting(false);
      }
    });
  };

  if (isClientsLoading)
    return (
      <>
        <Sidebar currentPageName={'Clients'} />
        <main className="lg:pl-72 flex items-center">
          <LoadingState />
        </main>
      </>
    );

  return (
    <ProductTourWrapper>
      <Sidebar currentPageName={'Clients'} />
      <AddClientModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        createClientMutation={createClientMutation}
        firstName={firstName}
        lastName={lastName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        submitClient={submitClient}
        isSubmitting={isSubmitting}
      />

      <main className="lg:ml-72">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 mb-12">
          {/* Heading */}
          <div className="border-b border-gray-200 pt-4 pb-6 text-gray-800">
            <h3 className="text-3xl font-bold">Your Clients</h3>
          </div>

          {/* If error loading clients */}
          {clientsError && <ErrorBanner message={clientsError.message} />}

          {/* Client List */}
          {clients?.length ? (
            <>
              <div className="mt-4 mb-6">
                <button
                  id="add-client-button"
                  onClick={() => {
                    moveToNextTourStep(clientsPageStepIndex); // Handle tour, if it is active
                    setIsModalOpen(true);
                  }}
                  type="button"
                  className="mt-4 inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-color duration-300"
                >
                  <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                  Add Client
                </button>
              </div>

              <ul role="list" className="divide-y divide-gray-100 mt-4 xl:w-3/4">
                {clients?.map((client) => <ClientListItem key={client.id} client={client} />)}
              </ul>
            </>
          ) : (
            // Empty State
            <EmptyState
              title="No Clients"
              description="Get started by creating your first client!"
              icon={UserCircleIcon}
              buttonText="Create Client"
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </div>
        <RecordAudioButton />
      </main>
    </ProductTourWrapper>
  );
}
