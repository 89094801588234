import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { shareMeetingNotes } from '../clients/meetingClient';
import { useGlobalContext } from '../context/GlobalProvider';
import { LoadingIcon } from './LoadingIcon';

interface ShareNotesModalProps {
  meetingId: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function ShareNotesModal({ meetingId, isOpen, setIsOpen }: ShareNotesModalProps) {
  const [emails, setEmails] = useState<string[]>([]);
  const [isSending, setIsSending] = useState(false);
  const { setState } = useGlobalContext();

  const shareNotesMutation = useMutation({
    mutationFn: ({ meetingId, emails }: { meetingId: number; emails: string[] }) => {
      return shareMeetingNotes(meetingId, emails);
    },
    onMutate: () => {
      setIsSending(true);
    },
    onSuccess: () => {
      setIsOpen(false);
      setEmails([]);

      setTimeout(() => {
        setIsSending(false);
      }, 1000);

      setState((prevState) => ({ ...prevState, shareNotesSuccess: true }));
    },
    onError: () => {
      setIsSending(false);
      setState((prevState) => ({ ...prevState, shareNotesError: true }));
    }
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (emails.length > 0) {
      shareNotesMutation.mutate({ meetingId, emails });
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
        {/* Grayed-out background */}
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        {/* Core Modal */}
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-12">
                <div>
                  <div className="text-center">
                    {/* Modal title */}
                    <DialogTitle as="h3" className="text-xl font-bold leading-6 text-gray-900">
                      Share Notes
                    </DialogTitle>
                    <p className="mt-2 text-sm text-gray-500">
                      Type in the email address(es) that you want to send these notes to. Add a
                      space after each address.
                    </p>

                    {/* Modal body */}
                    <div className="mt-6 sm:mx-auto sm:w-full">
                      <form className="space-y-4" onSubmit={(e) => handleSubmit(e)}>
                        <ReactMultiEmail
                          placeholder="Type email address(es) here"
                          emails={emails}
                          onChange={(_emails: string[]) => {
                            setEmails(_emails);
                          }}
                          autoFocus={true}
                          getLabel={(email, index, removeEmail) => {
                            return (
                              <div
                                className="!text-gray-700 !bg-indigo-100/70 hover:!bg-indigo-200 hover:cursor-default"
                                data-tag
                                key={index}
                              >
                                <div data-tag-item>{email}</div>
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />

                        <div>
                          <button
                            disabled={emails.length === 0 || isSending}
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 mt-8 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 disabled:bg-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Send Notes
                            {isSending && <LoadingIcon className="ml-2" />}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
