import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import AutoLogout from './components/AutoLogout';
import PrivateRoute from './components/PrivateRoute';
import RecordAudioPage from './components/RecordAudioPage';
import ClientPage from './pages/ClientPage';
import ClientsPage from './pages/ClientsPage';
import HomePage from './pages/HomePage';
import MeetingNotesPage from './pages/MeetingNotesPage';
import MeetingTemplatePage from './pages/MeetingTemplatePage';
import MeetingTemplatesPage from './pages/MeetingTemplatesPage';
import NotePage from './pages/NotePage';
import NotePageHardcoded from './pages/NotePage-old';
import NotFoundPage from './pages/NotFoundPage';
import RecordAudioPageExistingMeeting from './pages/RecordAudioPageExistingMeeting';

type AppProps = {
  pca: IPublicClientApplication;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/record" element={<RecordAudioPage />} />
        {/* <Route path="/reports" element={<ReportsPage />} /> */}
        <Route path="/clients" element={<ClientsPage />} />
        <Route path="/clients/:clientId" element={<ClientPage />} />
        <Route path="/meeting-notes" element={<MeetingNotesPage />} />
        <Route
          path="/meeting-notes/:meetingId/record"
          element={<RecordAudioPageExistingMeeting />}
        />
        <Route path="/meeting-notes/:meetingId" element={<NotePage />} />
        <Route path="/meeting-notes/1000" element={<NotePageHardcoded />} />
        <Route path="/meeting-templates" element={<MeetingTemplatesPage />} />
        <Route path="/meeting-templates/new" element={<MeetingTemplatePage />} />
        <Route path="/meeting-templates/:templateId" element={<MeetingTemplatePage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} /> {/* Catch all unmatched routes */}
    </>
  )
);

export default function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <RouterProvider router={router} />
      <AutoLogout />
    </MsalProvider>
  );
}
