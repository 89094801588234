import { Step } from 'react-joyride';

/**
 * Sometimes we need to take specific actions for certain steps.
 * Unfortunately, Joyride doesn't provide a way to do this dynamically,
 * so we need to hardcode the step indices.
 */
export const clientsPageStepIndex = 4;
export const addClientStepIndex = 5;
export const templatesPageStepIndex = 7;
export const templateBuilderStepIndex = 8;
export const addQuestionStepIndex = 9;

export const steps: Step[] = [
  // index: 0
  {
    title: 'Welcome!',
    content: (
      <h2>
        We'd like to show you around. Remember, you can always replay this tutorial by clicking the
        tutorial button on the home page.
      </h2>
    ),
    placement: 'center',
    target: 'body'
  },
  // index: 1
  {
    title: 'How it works',
    content: (
      <>
        <h2>Notewell takes notes and fills out paperwork for you. This is how it works: </h2>
        <ol className="list-decimal mt-3 ml-8 space-y-1 marker:font-bold">
          <li>You add a client.</li>
          <li>
            You build a meeting template to indicate what questions you want answered from your
            meeting.
          </li>
          <li>You record a conversation with your client.</li>
          <li>You upload the recording to Notewell.</li>
          <li>
            Notewell generates notes based on the questions you posed in your meeting template.
          </li>
        </ol>
        <p className="mt-3"></p>
      </>
    ),
    placement: 'center',
    target: 'body'
  },
  // index: 2
  {
    title: 'Easy Navigation',
    content:
      'These buttons let you quickly access your clients, notes, and templates from the home page.',
    target: '#linkContainer'
  },
  // index: 3
  {
    title: 'Adding a Client',
    content: 'Let\'s start by adding your first client! Click on "Your Clients"',
    target: '#home-clients-link',
    disableOverlayClose: true,
    spotlightClicks: true,
    data: {
      next: '/clients'
    }
  },
  // index: 4 - clientsPageStepIndex
  {
    title: 'Add a Client',
    content: 'Click on "Add Client" to fill in and submit your first client.',
    target: '#add-client-button',
    disableOverlayClose: true,
    spotlightClicks: true,
    data: {
      previous: '/'
    },
    placement: 'bottom'
  },
  // index: 5 - addClientStepIndex
  {
    title: 'Add a Client',
    content: "Fill this out with your client's information and click 'Add Client'.",
    target: '#add-client-modal',
    disableOverlayClose: true,
    spotlightClicks: true,
    placement: 'top'
  },
  {
    title: 'Meeting Templates',
    content:
      "Nice! You should be able to see your newly added client. Now let's make a meeting template. Click 'Meeting Templates'",
    target: '#templates-link',
    disableOverlayClose: true,
    spotlightClicks: true,
    placement: 'right',
    data: {
      next: '/meeting-templates'
    }
  },
  // index: 7 - templatesPageStepIndex
  {
    target: '#first-template',
    title: 'Meeting Templates',
    content: (
      <>
        <p>
          You can indicate what information you want to capture from your meetings using a
          <span className="font-bold"> meeting template</span>.
        </p>
        <br />
        <p>
          We've loaded an example meeting templates for you. Let's take a look! Click on the "View"
          button.
        </p>
      </>
    ),
    data: {
      previous: '/clients'
    },
    placement: 'auto',
    disableOverlayClose: true,
    spotlightClicks: true
  },
  // index: 8 - templateBuilderStepIndex
  {
    target: '#template-builder',
    title: 'Building a Meeting Template',
    content:
      "A meeting template consists of a list of questions that you want answered using content from a meeting. Each of these questions will be answered when you use Notewell's note-generation feature.",
    data: {
      previous: '/meeting-templates'
    },
    placement: 'top'
  },
  // index: 9 - addQuestionStepIndex
  {
    target: '#add-question-menu',
    title: 'Adding a Question',
    content: (
      <>
        <p>
          There are different types of question formats. You can hover over each question mark to
          read about them.
        </p>
        <p className="mt-2">
          You can click on any of these options to add a question to your template.
        </p>
      </>
    ),
    disableOverlayClose: true,
    spotlightClicks: true
  },
  // {
  //   target: 'body',
  //   title: '',
  //   content:
  //     "Here's the new question block you just added. Let's fill out the question with 'What were the main themes?'"
  // },
  {
    target: '#notes-link',
    title: 'Generating Meeting Notes',
    content:
      "Now that we've made a client and a template, let's upload a meeting recording and generate our first meeting note. Click 'Your Meeting Notes'.",
    disableOverlayClose: true,
    spotlightClicks: true,
    data: {
      next: '/meeting-notes'
    }
  },
  {
    target: 'body',
    title: '',
    content:
      "Click 'Add a Meeting' and fill out the details with the client and template we just made."
  },
  {
    target: 'body',
    title: '',
    content:
      "Now, upload a recorded conversation. Once it's done processing, we can look at what answers it came up with."
  },
  {
    target: 'body',
    title: '',
    content:
      "Here's the final result. If we want to add any further questions, we can ask them here."
  },
  {
    target: 'body',
    title: '',
    content: "That's all! If you have more questions, please reach out to us or ----"
  }
];
