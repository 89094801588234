import { AnswerValue } from '../common/types';

interface TemplateBlockContentDateProps {
  blockId: number;
  answer: Date;
  handleBlockAnswerUpdate: (blockId: number, newValue: AnswerValue) => void;
}

// Format dateTime to MM/DD/YYYY
const formatDateForInput = (dateTime: Date) => {
  if (!dateTime) return '';
  const date = new Date(dateTime);
  if (isNaN(date.getTime())) {
    console.error('Invalid date format');
    return undefined;
  }

  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();

  return `${year}-${month}-${day}`;
};

// Convert MM/DD/YYYY to Date object
const formatDateForServer = (date: string) => {
  const [year, month, day] = date.split('-');
  return new Date(Number(year), Number(month) - 1, Number(day));
};

export default function TemplateBlockContentDate({
  blockId,
  answer,
  handleBlockAnswerUpdate
}: TemplateBlockContentDateProps) {
  const date = formatDateForInput(answer);
  return (
    <input
      type="date"
      className="text-gray-600 border-b border-gray-300 focus:border-indigo-700 sm:px-1.5 sm:pb-1.5 sm:text-md bg-transparent"
      defaultValue={date}
      onChange={(e) => handleBlockAnswerUpdate(blockId, formatDateForServer(e.target.value))}
      required
    />
  );
}
