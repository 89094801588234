import { useState } from 'react';

const MIN_LENGTH = 1;
const MAX_LENGTH = 20;
const DEFAULT_LENGTH = 3;

// Ensure length of list is between 1 and 20. If less than 1, set to 1.
// If greater than 20, set to 20.
const validateLengthOfList = (value: number) => {
  if (value < MIN_LENGTH) return MIN_LENGTH;
  if (value > MAX_LENGTH) return MAX_LENGTH;
  return value;
};

export default function LengthofList({
  lengthOfList,
  onChange,
  isReadOnly
}: {
  lengthOfList?: number;
  onChange: (lengthOfList: number) => void;
  isReadOnly: boolean;
}) {
  const [unsanitizedLengthOfList, setUnsanitizedLengthOfList] = useState<string>(
    lengthOfList?.toString() || DEFAULT_LENGTH.toString()
  );

  // Validate the length of the list and update the parent component
  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = validateLengthOfList(Number(e.target.value));
    setUnsanitizedLengthOfList(newValue.toString());
    onChange(newValue);
  };

  return (
    <label>
      Length of List
      {!isReadOnly && (
        <span className="text-gray-400">
          {' '}
          ({MIN_LENGTH}-{MAX_LENGTH})
        </span>
      )}
      :
      <input
        type="number"
        min={MIN_LENGTH.toString()}
        max={MAX_LENGTH.toString()}
        className="ml-2 sm:px-1.5 sm:pb-1.5 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-300 focus:border-indigo-600 placeholder:text-gray-400 sm:text-base w-fit bg-transparent"
        value={unsanitizedLengthOfList}
        onChange={(e) => setUnsanitizedLengthOfList(e.target.value)}
        onBlur={(e) => handleUpdate(e)}
        disabled={isReadOnly}
        required
      />
    </label>
  );
}
