import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

interface DownloadAudioModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleContinue: () => void;
  file: File;
}

const downloadAudio = (file: File) => {
  const url = URL.createObjectURL(file); // Create temporary URL
  const link = document.createElement('a');
  link.href = url;
  link.download = file.name; // Set download filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url); // Clean up the URL object
};

export default function DownloadAudioModal({
  isOpen,
  setIsOpen,
  handleContinue,
  file
}: DownloadAudioModalProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        {/* Grayed-out background */}
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        {/* Core Modal */}
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform rounded-lg bg-white px-4 pb-4 pt-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-12">
                <button
                  type="button"
                  className="absolute top-6 right-6 text-gray-400 hover:text-indigo-600 focus:outline-none transition-color duration-300"
                  onClick={() => setIsOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div>
                  <div className="text-center">
                    {/* Modal title */}
                    {/* <DialogTitle as="h3" className="text-lg font-normal text-gray-900">
                      Download audio recording (recommended)
                    </DialogTitle> */}
                    <DialogTitle as="h3" className="text-lg font-bold text-gray-900">
                      Download
                    </DialogTitle>

                    {/* Modal body */}
                    <div className="flex flex-col sm:mx-auto mt-2 sm:w-full items-center justify-center">
                      <div className="flex items-start">
                        <p className="text-gray-700 text-base">
                          Download audio recording before continuing to generate notes (recommended)
                        </p>
                        {/* Tooltip */}
                        <div className="relative group h-fit mt-1">
                          <QuestionMarkCircleIcon
                            className="h-4 w-4 ml-1 -mr-1 text-gray-800 opacity-40 hover:stroke-2 hover:opacity-70 transition-opacity transition-stroke duration-300"
                            aria-hidden="false"
                          />
                          <span className="absolute block bottom-full bg-gray-500 left-8 top-0 h-fit w-64 text-white px-3 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                            We do not store your audio recording on our servers. Download it to keep
                            a copy. This is also useful if you want to re-use the recording.
                          </span>
                        </div>
                      </div>
                      <div className="flex mt-2">
                        <button
                          type="button"
                          onClick={() => downloadAudio(file)}
                          className="mr-4 justify-center rounded-md bg-indigo-600 px-3 py-1.5 mt-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-color duration-300"
                        >
                          Download
                        </button>
                        <button
                          type="button"
                          onClick={handleContinue}
                          className=" justify-center rounded-md bg-indigo-50 px-3 py-1.5 mt-4 text-sm font-semibold leading-6 text-indigo-700 shadow-sm hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-color duration-300"
                        >
                          Continue to generate notes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
