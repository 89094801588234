import { ApiError, Client } from '../common/types';
import { getAccessToken } from '../common/utils';
import { apiBaseUrl } from '../index';

// Create a new client
export const createClient = async (name: string): Promise<Client> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/clients`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({ name: name })
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return response.json();
};

// Retrieve all clients for the current user
export const getClients = async (): Promise<Client[]> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/clients`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return await response.json();
};

// Retrieve single client by id
export const getClientById = async (clientId: number): Promise<Client> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/clients/${clientId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new ApiError(await response.text(), response.status);
  }

  return await response.json();
};
