import { useEffect, useMemo } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

/**
 * Handle beforeunload event to prevent user from accidentally leaving the page.
 * Should be used on every page that can have unsaved changes.
 * Need to cache the function using useMemo in order to remove the event listener
 * when the component unmounts.
 * https://stackoverflow.com/questions/73860359/window-removeeventlistener-not-working-in-react
 */
const useUnsavedChangesWarning = (hasChanges: () => boolean) => {
  unstable_usePrompt({
    when: hasChanges(),
    message: 'You have unsaved changes. Are you sure you want to leave?'
  });

  const handleBeforeUnload = useMemo(() => {
    return (event: BeforeUnloadEvent) => {
      if (hasChanges()) {
        const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
        event.preventDefault();
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };
  }, [hasChanges]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
};

export default useUnsavedChangesWarning;
