import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { classNames } from '../common/utils';

export default function ErrorBanner({
  message,
  className
}: {
  message: string;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        className,
        'flex my-4 text-red-700 rounded-md border border-red-200 bg-red-50 align-middle py-3 px-4 content-center'
      )}
    >
      <div className="flex-shrink-0 align-middle content-center mr-3 text-red-400">
        <ExclamationTriangleIcon className="h-5 w-5" aria-hidden="true" />
      </div>
      <h1 className="text-sm font-medium">{message}</h1>
    </div>
  );
}
