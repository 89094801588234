import { PlusIcon } from '@heroicons/react/24/outline';

export default function EmptyState({
  icon: Icon,
  title,
  description,
  buttonText,
  onClick
}: {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
}) {
  return (
    <div className="mt-12 mx-4 relative block w-full lg:max-w-3xl rounded-lg border border-dashed border-gray-300 p-10 text-center justify-center">
      <div className="flex justify-center">
        <Icon className="h-8 w-8 stroke-1 text-gray-400" />
      </div>
      <h3 className="mt-2 text-lg font-semibold text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
      <div className="mt-6">
        <button
          onClick={() => onClick()}
          type="button"
          className="mt-1 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-color duration-300"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          {buttonText}
        </button>
      </div>
    </div>
  );
}
