import { User } from '../common/types';
import { getAccessToken } from '../common/utils';
import { apiBaseUrl } from '../index';

// Create a new client
export const createUser = async (name: string, email: string): Promise<User> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({ name: name, email: email })
  });
  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return response.json();
};

// Retrieve the current user
export const getCurrentUser = async (): Promise<User> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return await response.json();
};
