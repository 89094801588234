import { PuzzlePieceIcon } from '@heroicons/react/24/outline';

const SourceRequestButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="ml-3 h-5 relative group overflow-invisible">
      <button
        type="button" // This is important - prevents this button from submitting form
        onClick={onClick}
        className="h-5 w-5 text-indigo-600 stroke-2 opacity-50 hover:opacity-100 transition-opacity duration-300"
      >
        <PuzzlePieceIcon />
      </button>

      {/* Tooltip */}
      <span className="absolute block bottom-full overflow-visible bg-gray-500 right-8 top-0 h-16 w-48 text-white px-3 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-300">
        Click me to see which lines in the transcript were used to generate this answer!
      </span>
    </div>
  );
};

export default SourceRequestButton;
