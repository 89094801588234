import { ChangeEvent } from 'react';
import { AnswerValue, OptionsAnswer } from '../common/types';

interface TemplateBlockContentSingleSelectProps {
  blockId: number;
  answer: OptionsAnswer;
  handleBlockAnswerUpdate: (blockId: number, newValue: AnswerValue) => void;
}

export default function TemplateBlockContentSingleSelect({
  blockId,
  answer,
  handleBlockAnswerUpdate
}: TemplateBlockContentSingleSelectProps) {
  /**
   * Call parent state setter to update template block
   * answer value when a new option is selected
   */
  const onChange = (e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const updatedAnswerDict: OptionsAnswer = {};
    // Initially set all keys set to false
    Object.keys(answer).forEach((key) => {
      updatedAnswerDict[key] = false;
    });
    updatedAnswerDict[e.target.value] = true;
    handleBlockAnswerUpdate(blockId, updatedAnswerDict);
  };

  return (
    // Dropdown if over 5 options
    Object.keys(answer).length > 5 ? (
      <select
        onChange={(e) => onChange(e)}
        className="sm:px-1.5 sm:pb-1.5 focus:text-gray-900 text-gray-600 border-b outline-none border-gray-300 focus:border-indigo-700 placeholder:text-gray-400 sm:text-md sm:leading-6"
        required
      >
        {Object.keys(answer).map((option) => (
          <option key={option} value={option} selected={answer[option]}>
            {option}
          </option>
        ))}
      </select>
    ) : (
      // Radio buttons otherwise
      <div className="space-y-2">
        {Object.keys(answer).map((option, index) => (
          <label key={index} className="flex items-center">
            <input
              type="radio"
              value={option}
              checked={answer[option]}
              onChange={(e) => onChange(e)}
              // name="single-select" // this creates a weird bug where
              required={index === 0}
            />
            <span className="ml-1.5 text-sm">{option}</span>
          </label>
        ))}
      </div>
    )
  );
}
