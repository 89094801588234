import { MicrophoneIcon } from '@heroicons/react/20/solid';
import useNavigation from './common/navigation';

export default function RecordAudioButton() {
  const { goToRecordPage } = useNavigation();
  return (
    <button
      onClick={() => goToRecordPage()}
      className="group print:hidden fixed p-4 bottom-8 lg:bottom-16 right-8 lg:right-16 rounded-full border-4 border-indigo-200 bg-indigo-600 shadow-lg shadow-slate-400 hover:bg-indigo-100 transition-color duration-300"
    >
      <MicrophoneIcon className="group-hover:text-indigo-600 h-6 w-6 lg:h-8 lg:w-8 text-white transition-color duration-300" />
    </button>
  );
}
