import { XMarkIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/24/outline';

const MAX_OPTIONS = 10;

export default function OptionList({
  options,
  setOptions,
  isReadOnly
}: {
  options: string[];
  setOptions: (options: string[]) => void;
  isReadOnly: boolean;
}) {
  const handleAddOption = () => {
    if (options.length < MAX_OPTIONS) {
      setOptions([...options, '']);
    }
  };

  const handleUpdateOption = (index: number, newValue: string) => {
    const updatedOptionList = [...options];
    updatedOptionList[index] = newValue;
    setOptions(updatedOptionList);
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  return (
    <div className="relative">
      <ol className="list-[lower-alpha] list-inside space-y-3 text-md">
        {options.map((option, index) => {
          return (
            <li key={index}>
              <div className="relative inline-block w-3/4">
                <input
                  className="w-full p-1 pr-8 outline-none border-b border-gray-200 focus:text-black focus:border-indigo-600 transition-border duration-200 bg-transparent"
                  placeholder={'Option ' + (index + 1)}
                  value={option}
                  onChange={(e) => handleUpdateOption(index, e.target.value)}
                  onBlur={(e) => handleUpdateOption(index, e.target.value.trim())} // Trim whitespace when focus is lost
                  required
                  disabled={isReadOnly}
                />
                {/* Display error message if option list is empty */}
                {!isReadOnly && index === 0 && options.every((str) => str === '') && (
                  // Hover over the icon to see the error message
                  <div className="absolute group top-2 right-2">
                    <ExclamationTriangleIcon
                      className="h-4 w-4 text-orange-600/80 group-hover:text-orange-600"
                      aria-hidden="true"
                    />
                    {/* Tooltip */}
                    <span className="absolute block bottom-full bg-gray-500 left-5 top-0 h-fit w-32 text-wrap text-white px-3 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                      Please fill in at least one option
                    </span>
                  </div>
                  // ) : (
                  //   <div className="group absolute right-1 bottom-2">
                  //     <CheckIcon className="h-4 w-4 text-teal-500" aria-hidden="true" />
                  //   </div>
                  // )
                )}
              </div>
              {options.length > 1 && !isReadOnly ? (
                <XMarkIcon
                  className="inline-block h-5 w-5 ml-2 cursor-pointer text-gray-300 hover:text-indigo-600 transition-colors duration-300"
                  onClick={() => handleRemoveOption(index)}
                />
              ) : null}
            </li>
          );
        })}
      </ol>

      {options.length < MAX_OPTIONS && !isReadOnly ? (
        <div
          className="flex cursor-pointer items-center text-sm font-medium mt-5 bg-indigo-50 hover:bg-indigo-500 text-indigo-800 hover:text-white transition-colors rounded-md py-1.5 px-2.5 w-fit text-center"
          onClick={() => handleAddOption()}
        >
          <PlusIcon className="h-4 w-4 inline-block mr-1" />
          Add Option
        </div>
      ) : null}
    </div>
  );
}
