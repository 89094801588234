import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import BasicMeetingDataSection from '../components/BasicMeetingDataSection-old';
import CollapsibleNoteSection from '../components/CollapsibleNoteSection';
import NoteSectionBodyGeneralInfo from '../components/hardcoded_note_bodies/NoteSectionBodyGeneralInfo';
import NoteSectionBodyMeeting from '../components/hardcoded_note_bodies/NoteSectionBodyMeeting';
import NoteSectionBodyNextSteps from '../components/hardcoded_note_bodies/NoteSectionBodyNextSteps';
import NoteSectionApprovedAlert from '../components/NoteSectionApprovedAlert';
import NoteSubmittedModal from '../components/NoteSubmittedModal';
import Sidebar from '../components/Sidebar';
import TranscriptColumn from '../components/TranscriptColumn';
import meetingData from '../json/meetingData.json';

const _APPROVAL_ALERT_TIMEOUT = 3000;

export type BasicMeetingDataOld = {
  worker: string;
  date: string;
  time: string;
  client: string;
  location: string;
};

// This is to make the date of meeting listed on the meeting page, which
// is dynamically generated to be today, to match the date of the meeting in the notes, which
// is defined in a JSON and therefore cannot be dynamically generated
const _hijackMeetingDateLol = (basicData: BasicMeetingDataOld): BasicMeetingDataOld => {
  basicData.date = new Date().toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
  return basicData;
};

export default function NotePage() {
  const [basicData, setBasicData] = useState<BasicMeetingDataOld>(
    _hijackMeetingDateLol(meetingData.basicData)
  );
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const [isApprovalAlertShown, setIsApprovalAlertShown] = useState(false);
  const [isTranscriptSourceBarOpen, setIsTranscriptSourceBarOpen] = useState(false); // Must be defined before noteSections
  const [transcriptSourceIndices, setTranscriptSourceIndices] = useState<number[]>([]); // Indices of transcript used to generate answers

  // Actions taken when the "Approve Section" button is clicked
  const approveSection = () => {
    // Update parent's state
    setIsApprovalAlertShown(true); // Update component's state
    setTimeout(setIsApprovalAlertShown, _APPROVAL_ALERT_TIMEOUT, false); // Hide alert after some time
  };

  /**
   * Handles the click event for a source request button.
   * Opens the transcript source bar, and feeds it the source indices.
   */
  const handleSourceRequest = (sourceIndices: number[]) => {
    setIsTranscriptSourceBarOpen(true);
    setTranscriptSourceIndices(sourceIndices);
  };

  // Sorry this is so messy. This must be defined within the body of NotePage
  const noteSections = [
    {
      id: 1,
      question: 'General Information',
      description: "Meeting purpose, Client's preferred language",
      body: <NoteSectionBodyGeneralInfo handleSourceRequest={handleSourceRequest} />,
      isApproved: false
    },
    {
      id: 2,
      question: 'Meeting',
      description: 'Updates, Themes, Barriers, Referrals',
      body: <NoteSectionBodyMeeting handleSourceRequest={handleSourceRequest} />,
      isApproved: false
    },
    {
      id: 3,
      question: 'Next Steps',
      description: 'Following meeting(s), Action steps',
      body: <NoteSectionBodyNextSteps handleSourceRequest={handleSourceRequest} />,
      isApproved: false
    }
  ];

  const [noteData, setNoteData] = useState(noteSections); // Must be defined after noteSections

  // Update the isApproved attribute of a single section in noteData state object
  const _updateisApproved = (isApproved: boolean, id: number) => {
    // Create a new object with the updated attribute
    const updatedNoteData = noteData.map((noteSection) => {
      if (noteSection.id === id) {
        return { ...noteSection, isApproved };
      }
      return noteSection;
    });

    // Update the state with the modified array
    setNoteData(updatedNoteData);
  };

  return (
    <>
      <Sidebar currentPageName={'Meeting Notes'} />
      <NoteSectionApprovedAlert open={isApprovalAlertShown} />
      <main className="lg:pl-72">
        <div className="xl:pr-96">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 mb-12">
            {
              <div>
                {/* Heading */}
                <div className="border-b border-gray-200 pt-4 pb-6">
                  <h3 className="text-3xl font-bold">{meetingData.meetingType}</h3>
                </div>
                {/* Basic Meeting Data*/}
                <BasicMeetingDataSection
                  basicMeetingData={basicData}
                  setBasicMeetingData={setBasicData}
                />
                {/* Collapsible Note Sections */}
                <dl className="pt-5 mx-auto max-w-7xl">
                  {noteData.map((section, index) => (
                    <CollapsibleNoteSection
                      key={index}
                      id={section.id}
                      question={section.question}
                      description={section.description}
                      isApproved={section.isApproved}
                      setisApproved={(isApproved) => {
                        _updateisApproved(isApproved, section.id);
                        approveSection();
                      }}
                    >
                      {section.body}
                    </CollapsibleNoteSection>
                  ))}
                </dl>
                {/* Submit Note Button */}
                <div className="flex flex-col items-center w-full">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-2 mt-10 rounded-md bg-indigo-600 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    disabled={!noteData.every((section) => section.isApproved === true)}
                    onClick={() => setIsSubmissionModalOpen(true)}
                  >
                    Submit Notes
                    <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                <NoteSubmittedModal
                  open={isSubmissionModalOpen}
                  setOpen={setIsSubmissionModalOpen}
                />
              </div>
            }
          </div>
        </div>
        <TranscriptColumn
          isTranscriptSourceBarOpen={isTranscriptSourceBarOpen}
          setIsTranscriptSourceBarOpen={setIsTranscriptSourceBarOpen}
          transcriptSourceIndices={transcriptSourceIndices}
          transcriptChunks={[]}
        />
      </main>
    </>
  );
}
