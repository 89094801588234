// utils.ts
import { msalInstance } from '..';
import { protectedResources } from '../authconfig';
import {
  Meeting,
  TemplateBlock,
  TemplateBlockAndAnswer,
  TemplateBlockAnswer,
  TemplateElements
} from './types';

/**
 * Returns the access token from the MSAL instance
 */
export const getAccessToken = async (): Promise<string> => {
  const request = {
    scopes: [protectedResources.notewellServerApi.scopes[0]]
  };
  const response = await msalInstance.acquireTokenSilent(request);
  return response.accessToken;
};

export const classNames = (...classes: (string | boolean | undefined)[]) =>
  classes.filter(Boolean).join(' ');

export const getInitials = (fullName: string): string => {
  const names = fullName.split(' ');
  const initials = names.map((name) => name.charAt(0).toUpperCase()).join('');
  return initials;
};

/**
 * Converts enum values into a readable strings
 */
export const getReadableString = (templateElement: TemplateElements): string => {
  switch (templateElement) {
    case TemplateElements.SingleSelect:
      return 'Single-Select';
    case TemplateElements.MultiSelect:
      return 'Multi-Select';
    case TemplateElements.ShortAnswer:
      return 'Short Answer';
    case TemplateElements.ShortAnswerList:
      return 'Short Answer List';
    case TemplateElements.Paragraph:
      return 'Paragraph ';
    case TemplateElements.Date:
      return 'Date';
    case TemplateElements.Time:
      return 'Time';
  }
};

/**
 * Combines arrays of template blocks and answers together
 * into an object {block, answer}
 */
export const zipTemplateBlocksAndAnswers = (
  blocks: TemplateBlock[],
  answers: TemplateBlockAnswer[]
): TemplateBlockAndAnswer[] => {
  return (
    blocks
      // Combine blocks with their answers
      .map((block: TemplateBlock) => {
        const answer = answers.find(
          (answer: TemplateBlockAnswer) => block.id === answer.templateBlockId
        );
        return { block, answer: answer };
      })
  );
};

/**
 * Sorts a list of meetings. If descending = true, sorts from future to past.
 * If false, sorts from past to future
 */
export const sortMeetings = (meetings: Meeting[], descending = true) =>
  meetings.sort((a: Meeting, b: Meeting) => {
    const dateTimeA = a.dateTime.getTime();
    const dateTimeB = b.dateTime.getTime();
    const createdAtA = a.createdAt!.getTime();
    const createdAtB = b.createdAt!.getTime();

    if (dateTimeA === dateTimeB) {
      // If dateTime is the same, use createdAt as tiebreaker
      return descending ? createdAtB - createdAtA : createdAtA - createdAtB;
    }

    return descending ? dateTimeB - dateTimeA : dateTimeA - dateTimeB;
  });
// ====================================================================================================
// =================================== Utils for Time and Dates =======================================
// ====================================================================================================
/**
 * Takes in a date "YYYY-MM-DD" and a time "HH:MM"
 * and combines them into a single Date object
 */
export const combineDateAndTime = (date: string, time: string): Date => {
  // Validate date format (YYYY-MM-DD)
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(date)) {
    throw new Error('Invalid date format. Please use YYYY-MM-DD format.');
  }

  // Validate time format (HH:MM)
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  if (!timeRegex.test(time)) {
    throw new Error('Invalid time format. Please use HH:MM format.');
  }

  // Combine date and time into a single string
  const dateTimeString = `${date}T${time}:00`; // e.g., "2024-05-28T14:30:00"

  // Create a Date object
  return new Date(dateTimeString);
};

// Converts a Date object into a locale-aware string YYYY-MM-DD
// This is the only format that is both natively sortable
// and can be used as a key in a map
export const getSortableDateLocaleString = (date: Date): string => {
  const localeDateString = date.toLocaleDateString('en-US');

  const [month, day, year] = localeDateString.split('/');

  // Ensure that month, day, and year are zero-padded (2-digit month/day and 4-digit year)
  const paddedMonth = month.padStart(2, '0');
  const paddedDay = day.padStart(2, '0');
  const paddedYear = year.padStart(4, '0'); // Although it's already 4 digits

  return `${paddedYear}-${paddedMonth}-${paddedDay}`;
};

// Converts a locale-aware date string (YYYY-MM-DD) into a readable date string (Month DD, YYYY)
export const getFormattedDateFromLocaleString = (dateString: string): string => {
  const [year, month, day] = dateString.split('-');
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  return getFormattedDateString(date);
};

// Formats date object as Month DD, YYYY
export const getFormattedDateString = (date: Date): string => {
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

// Formats time as HH:MM AM/PM
export const getFormattedTimeString = (date: Date): string => {
  // Format the time as hh:mm am/pm
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

// Formats date as yyyy/mm/dd. This is the format expected by HTML date input
export const getFormattedDateStringForDateInput = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// Formats time as HH:MM
export const getFormattedTimeStringForTimeInput = (date: Date): string => {
  // Format the time as hh:mm
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
};

// Returns true if date is in the past, false if in the future
export const isPast = (date: Date): boolean => {
  const now = new Date();
  return date < now;
};

// Generates a string representing the relative time since the given date
// e.g., "3 days ago", "just now", etc.
export function timeSince(date: Date): string {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 2) return `${interval} years ago`;
  if (interval === 1) return '1 year ago';
  interval = Math.floor(seconds / 2592000);
  if (interval >= 2) return `${interval} months ago`;
  if (interval === 1) return '1 month ago';
  interval = Math.floor(seconds / 86400);
  if (interval >= 2) return `${interval} days ago`;
  if (interval === 1) return '1 day ago';
  interval = Math.floor(seconds / 3600);
  if (interval >= 2) return `${interval} hours ago`;
  if (interval === 1) return '1 hour ago';
  interval = Math.floor(seconds / 60);
  if (interval >= 2) return `${interval} minutes ago`;
  if (interval === 1) return '1 minute ago';
  return 'just now';
}
