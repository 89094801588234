import { ExclamationTriangleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { forwardRef, ReactNode } from 'react';
import { TemplateElements } from '../common/types';
import { classNames, getReadableString } from '../common/utils';
import useAutoResizeTextarea from '../hooks/useAutoResizeTextarea';

interface TemplateBlockProps {
  index: number;
  templateElementType: TemplateElements;
  deleteBlock: () => void;
  question: string;
  setQuestion: (value: string) => void;
  children: ReactNode | null;
  isReadOnly: boolean;
  isHighlighted?: boolean;
}

const TemplateBlock = forwardRef<HTMLDivElement, TemplateBlockProps>(function TemplateBlock(
  {
    index,
    templateElementType,
    deleteBlock,
    question,
    setQuestion,
    isReadOnly,
    children,
    isHighlighted = false
  }: TemplateBlockProps,
  ref
) {
  const isQuestionEmpty = question.trim() === '';
  const { textareaRef, rows } = useAutoResizeTextarea(question);

  return (
    <div
      ref={ref}
      className={classNames(
        'col-span-1 rounded-lg bg-white transition-color duration-500',
        isHighlighted
          ? 'ring-4 ring-indigo-500/20 border border-indigo-700 bg-indigo-50'
          : 'ring-4 ring-transparent border border-gray-200' // We do this so that the transition fades out
        // isQuestionEmpty ? 'border-indigo-500 ring-1 ring-indigo-500/20' : ''
      )}
    >
      {' '}
      {/* divide-y divide-gray-200 */}
      <div
        className={classNames(
          'flex w-full items-center justify-between space-x-6 px-6 pt-6',
          isReadOnly ? 'pb-6' : ''
        )}
      >
        <div className="flex-1">
          {/* truncate */}
          <div className="flex items-center space-x-4 lg:space-x-8 justify-between mb-5">
            {/* Question Input */}
            <div className="flex w-full">
              <p className="mr-2 text-gray-500">{index + 1}.</p>
              <div className="relative w-full">
                <textarea
                  ref={textareaRef}
                  rows={rows}
                  className={classNames(
                    // isQuestionEmpty ? '!border-indigo-500' : '',
                    'w-full text-base pb-1 pr-8 outline-none border-b border-gray-300 focus:border-indigo-600 transition-border duration-300'
                  )}
                  // type="text"
                  placeholder="Question"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  onBlur={(e) => setQuestion(e.target.value.trim())} // Trim whitespace when focus is lost
                  required
                  disabled={isReadOnly}
                />
                {/* Display error message if question is empty */}
                {!isReadOnly && isQuestionEmpty && (
                  // Hover over the icon to see the error message
                  <div className="group absolute right-4 top-2">
                    <ExclamationTriangleIcon
                      className="h-4 w-4 text-orange-600/80 group-hover:text-orange-600"
                      aria-hidden="true"
                    />
                    {/* Tooltip */}
                    <span className="absolute block bottom-full bg-gray-500 left-6 top-0 h-fit w-64 text-wrap text-white px-3 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                      No answers will be generated for this question if it is left empty. If you
                      want to remove this question, click the trash icon.
                    </span>
                  </div>
                  // : (
                  //   <div className="group absolute right-4 bottom-3">
                  //     <CheckIcon className="h-4 w-4 text-teal-500" aria-hidden="true" />
                  //   </div>
                  // )
                )}
              </div>
            </div>
            {/* Pill/Tag showing what kind of Template Element this block is */}
            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-indigo-50 px-2.5 py-0.5 text-xs font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20">
              {getReadableString(templateElementType)}
            </span>
          </div>
          {/* Block Body, where are the dynamic/variable stuff is */}
          <div className="mt-1 mx-4 truncate text-sm text-gray-500">{children}</div>
        </div>
      </div>
      {/* Bottom Buttons */}
      {!isReadOnly && (
        <div className="-mt-px flex">
          {/* <div className="flex w-0 flex-1">
                <a
                  href={`mailto:hello`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Email
                </a>
              </div> */}
          <div className="-ml-px flex w-0 flex-1">
            <div className="relative inline-flex w-0 flex-1 items-center justify-end gap-x-3 rounded-br-lg py-2 text-xs font-normal text-gray-600">
              <div
                className="cursor-pointer flex opacity-60 hover:opacity-100 transition-opacity duration-300 items-center"
                onClick={() => {
                  const isConfirmed = window.confirm(
                    'Are you sure you want to remove this question?'
                  );
                  if (isConfirmed) {
                    deleteBlock();
                  }
                }}
              >
                <TrashIcon className="h-4 w-4 mr-6 mb-3" aria-hidden="true" />
                {/* <span>Delete</span> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default TemplateBlock;
