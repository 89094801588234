import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon
} from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';

export default function TranscriptSearchBar({
  searchTerm,
  setSearchTerm,
  highlightedLineIndex,
  setHighlightedLineIndex,
  transcriptChunks,
  setWindowHash
}: {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  highlightedLineIndex: number;
  setHighlightedLineIndex: (index: number) => void;
  transcriptChunks: { text: string }[];
  setWindowHash: (index: number) => void;
}) {
  // const [searchTerm, setSearchTerm] = useState('');
  const [searchMatchIndices, setSearchMatchIndices] = useState<number[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  // When the index changes, update the current index, update the highlighted line, and scroll to it
  const handleNewIndex = (newIndex: number) => {
    setCurrentIndex(newIndex);
    setHighlightedLineIndex(searchMatchIndices[newIndex]);
    setWindowHash(searchMatchIndices[newIndex]);
  };

  const handleSearchUp = () => {
    if (searchMatchIndices.length > 0) {
      // Find the index of the current highlighted line
      const currentIndex = searchMatchIndices.indexOf(highlightedLineIndex);
      // If the current line is the first match, loop back to the last match
      const newIndex = currentIndex === 0 ? searchMatchIndices.length - 1 : currentIndex - 1;
      handleNewIndex(newIndex);
    }
  };

  const handleSearchDown = () => {
    if (searchMatchIndices.length > 0) {
      // Find the index of the current highlighted line
      const currentIndex = searchMatchIndices.indexOf(highlightedLineIndex);
      // If the current line is the last match, loop back to the first match
      const newIndex = currentIndex === searchMatchIndices.length - 1 ? 0 : currentIndex + 1;
      handleNewIndex(newIndex);
    }
  };

  // For search term changes
  useEffect(() => {
    if (searchTerm) {
      // Find indices of chunks that match the search term
      const indices = transcriptChunks
        .map((chunk, index) =>
          chunk.text.toLowerCase().includes(searchTerm.toLowerCase()) ? index : -1
        )
        .filter((index) => index !== -1); // Remove -1 values

      setSearchMatchIndices(indices);
      // Highlight the first match
      if (indices.length > 0) {
        setHighlightedLineIndex(indices[0]);
        setWindowHash(indices[0]);
      } else {
        setHighlightedLineIndex(-1);
      }
      setCurrentIndex(0);
      if (inputRef.current) inputRef.current.focus();
    } else {
      // Reset the matching indices and count if no search term
      setSearchMatchIndices([]);
      setCurrentIndex(0);
      setHighlightedLineIndex(-1);
      window.history.replaceState(null, '', ' ');
    }
  }, [searchTerm, transcriptChunks]);

  return (
    /* Search Bar */
    <div className="w-full items-center justify-center flex shadow-sm">
      <div className="w-80 py-4">
        <label htmlFor="search" className="sr-only">
          Search Transcript
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </div>
          <input
            ref={inputRef}
            id="search"
            name="search"
            type="text"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            value={searchTerm}
            placeholder="Search transcript"
            className="block w-full border-0 bg-white py-1.5 pl-10 pr-3 text-gray-600 ring-1 rounded ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <div className="absolute text-sm inset-y-0 right-0 pr-2 py-1.5 space-x-0.5 text-gray-600 flex flex-row items-center justify-center">
            {searchTerm && (
              <p className="text-gray-600 text-sm mr-4">
                {searchMatchIndices.length === 0 ? 0 : currentIndex + 1} /{' '}
                {searchMatchIndices.length}
              </p>
            )}
            <button
              onClick={handleSearchUp}
              disabled={searchMatchIndices.length === 0}
              className="disabled:text-gray-300"
            >
              <ChevronUpIcon className="h-5 w-5 mt-0.5 disabled:text-gray-300" />
            </button>
            <button
              onClick={handleSearchDown}
              disabled={searchMatchIndices.length === 0}
              className="disabled:text-gray-300"
            >
              <ChevronDownIcon className="h-5 w-5 " />
            </button>
            <button
              onClick={() => setSearchTerm('')}
              disabled={!searchTerm}
              className="disabled:text-gray-300"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
