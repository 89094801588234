import { ApiError, Template, TemplateBlock, TemplateBlockCreate } from '../common/types';
import { getAccessToken } from '../common/utils';
import { apiBaseUrl } from '../index';

// Retrieve all templates
export const getTemplates = async () => {
  const response = await fetch(apiBaseUrl + '/templates', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    }
  });
  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }
  return await response.json();
};

// Get a template and its associated blocks by template Id
export const getTemplateAndBlocksById = async (templateId: number) => {
  const response = await fetch(apiBaseUrl + '/templates/' + templateId, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    }
  });

  if (!response.ok) {
    throw new ApiError(await response.text(), response.status);
  }

  const { template, blocks } = await response.json();

  // for blocks with optArgs == null, set to undefined for consistency
  const clientBlocks = blocks.map((block: TemplateBlock) => {
    return {
      ...block,
      optArgs: block.optArgs || undefined
    };
  });

  // Sort blocks
  clientBlocks.sort((a: TemplateBlock, b: TemplateBlock) => a.position! - b.position!);

  return { template, blocks: clientBlocks };
};

// Create a new template and its corresponding blocks
export const createTemplateAndBlocks = async ({
  templateData,
  templateBlockData
}: {
  templateData: Template;
  templateBlockData: TemplateBlockCreate[];
}) => {
  // API request to create template
  const templateResponse = await fetch(apiBaseUrl + '/templates', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    },
    body: JSON.stringify(templateData)
  });

  // Check if the template creation was successful
  if (!templateResponse.ok) {
    throw new Error(`${await templateResponse.text()}`);
  }

  const template = await templateResponse.json();
  const templateId = template.id;

  // Attach blocks to the template
  const blockPromises = templateBlockData.map(async (block: TemplateBlockCreate, index: number) => {
    // Translate block into expected server-side shape
    // Don't unpack block, because it has an id and other fields that we don't need
    // TODO: marry client- and server-side representations of TemplateBlock
    const serverSideBlock = {
      question: block.question,
      position: index,
      type: block.type,
      optArgs: block.optArgs
    };

    // Create the block and return its response
    const blockResponse = await fetch(
      apiBaseUrl + '/templates/' + templateId + '/template_blocks',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getAccessToken()}`
        },
        body: JSON.stringify(serverSideBlock)
      }
    );

    // Check if the block creation was successful
    if (!templateResponse.ok) {
      throw new Error(`${await blockResponse.text()}`);
    }

    return blockResponse.json();
  });

  // Wait for all block creation requests to complete
  const blockResponses = await Promise.all(blockPromises);

  // Handle responses
  // For simplicity, log the responses here
  console.log('Template created:', template);
  blockResponses.forEach((blockResponse, index) => {
    console.log(`Block ${index + 1} created:`, blockResponse);
  });

  // Optionally, return any relevant data or identifiers
  return { templateId };
};

export const updateTemplateById = async (templateId: number, templateTitle: string) => {
  const response = await fetch(apiBaseUrl + '/templates/' + templateId, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    },
    body: JSON.stringify({ title: templateTitle })
  });
  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }
  return await response.json();
};

// Delete a template and its associated template blocks by ID
export const deleteTemplateById = async (templateId: number) => {
  const response = await fetch(apiBaseUrl + '/templates/' + templateId, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    }
  });
  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }
  return await response.json();
};

// Determine if a template has been used to process a meeting/generate notes
export const getIsTemplateUsed = async (templateId: number) => {
  const response = await fetch(apiBaseUrl + '/templates/' + templateId + '/used', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    }
  });
  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }
  return await response.json();
};

export const duplicateTemplateById = async (templateId: number): Promise<Template> => {
  const response = await fetch(apiBaseUrl + '/templates/' + templateId + '/duplicate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    }
  });
  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }
  return await response.json();
};
