import { AnswerValue } from '../common/types';
import useAutoResizeTextarea from '../hooks/useAutoResizeTextarea';

interface TemplateBlockContentParagraphProps {
  blockId: number;
  answer: string;
  handleBlockAnswerUpdate: (blockId: number, newValue: AnswerValue) => void;
}

export default function TemplateBlockContentParagraph({
  blockId,
  answer,
  handleBlockAnswerUpdate
}: TemplateBlockContentParagraphProps) {
  const { textareaRef, rows } = useAutoResizeTextarea(answer);

  return (
    <textarea
      ref={textareaRef}
      rows={rows}
      className="sm:px-1.5 sm:pb-1.5 text-gray-600 focus:text-gray-700 border-b outline-none border-gray-300 bg-transparent focus:border-indigo-700 placeholder:text-gray-400 sm:text-md sm:leading-7 w-full"
      defaultValue={answer}
      onChange={(e) => handleBlockAnswerUpdate(blockId, e.target.value)}
      required
    />
  );
}
