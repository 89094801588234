// Enum for tempalte save status
export enum SaveStatus {
  SAVED = 'Saved',
  SAVING = 'Saving...',
  ERROR = 'Error',
  OFFLINE = 'Offline'
}

// Unforunately we have to explicitly define every single tailwind color class here
// instead of dynamically generating them. This is because Tailwind JIT will purge
// the dynamic styles and they will not be applied.
const statusColors = {
  [SaveStatus.SAVED]: {
    bg: 'bg-teal-50',
    text: 'text-teal-900',
    border: 'border-teal-800/20',
    indicator: 'bg-teal-500'
  },
  [SaveStatus.SAVING]: {
    bg: 'bg-yellow-50',
    text: 'text-yellow-900',
    border: 'border-yellow-800/20',
    indicator: 'bg-yellow-500'
  },
  [SaveStatus.ERROR]: {
    bg: 'bg-red-50',
    text: 'text-red-900',
    border: 'border-red-800/20',
    indicator: 'bg-red-600'
  },
  [SaveStatus.OFFLINE]: {
    bg: 'bg-gray-50',
    text: 'text-gray-900',
    border: 'border-gray-800/20',
    indicator: 'bg-gray-500'
  }
};

export default function SaveStatusIndicator({ status }: { status: SaveStatus }) {
  return (
    <div
      className={`${statusColors[status].bg} ${statusColors[status].border} fixed flex items-center align-items bottom-12 right-12 rounded-full border px-4 py-1`}
    >
      {/* Circle color indicator */}
      <div
        className={`inline-block w-2.5 h-2.5 rounded-full mr-2 ${statusColors[status].indicator}`}
      />
      <span className={`font-medium ${statusColors[status].text}`}>{status}</span>
    </div>
  );
}
