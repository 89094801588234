import { AnswerValue } from '../common/types';

interface TemplateBlockContentShortAnswerProps {
  blockId: number;
  answer: string;
  handleBlockAnswerUpdate: (blockId: number, newValue: AnswerValue) => void;
}

export default function TemplateBlockContentShortAnswer({
  blockId,
  answer,
  handleBlockAnswerUpdate
}: TemplateBlockContentShortAnswerProps) {
  return (
    <input
      type="text"
      // w-96
      className="col-span-2 sm:col-span-1 w-full sm:px-1.5 sm:pb-1.5 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-300 bg-transparent focus:border-indigo-700 placeholder:text-gray-400 sm:text-md sm:leading-6"
      defaultValue={answer}
      onChange={(e) => handleBlockAnswerUpdate(blockId, e.target.value)}
      required
    />
  );
}
