import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getMeetingsByClientId } from '../clients/meetingClient';
import useNavigation from '../common/navigation';
import { Client, Meeting } from '../common/types';
import { getFormattedDateString, getInitials } from '../common/utils';
import ErrorBanner from './ErrorBanner';

/**
 * Takes in a list of meetings and computes the most recent meeting
 * that occured in the past, and the closest meeting that will
 * occur in the future.
 */
const findLatestAndUpcomingMeetingDates = (
  meetings: Meeting[]
): {
  latestMeetingDate: string;
  upcomingMeetingDate: string;
} => {
  let latestMeetingDate = '';
  let upcomingMeetingDate = '';

  const now = new Date();

  for (const meeting of meetings) {
    if (meeting.dateTime < now) {
      latestMeetingDate = getFormattedDateString(meeting.dateTime); // This will be the last past meeting due to the sorted nature of the array
    } else if (meeting.dateTime > now && !upcomingMeetingDate) {
      upcomingMeetingDate = getFormattedDateString(meeting.dateTime);
      // Once the first future meeting is found, we break out of the loop since meetings are sorted.
      break;
    }
  }

  return { latestMeetingDate, upcomingMeetingDate };
};

export default function ClientListItem({ client }: { client: Client }) {
  const [latestMeeting, setLatestMeeting] = useState('');
  const [upcomingMeeting, setUpcomingMeeting] = useState('');

  const { goToClientPage } = useNavigation();

  // Fetch meetings for client
  const { data: meetings, error } = useQuery({
    queryKey: ['getMeetingsByClientId', client.id],
    queryFn: () => getMeetingsByClientId(client.id)
  });

  useEffect(() => {
    if (meetings && meetings.length > 0) {
      const { latestMeetingDate, upcomingMeetingDate } =
        findLatestAndUpcomingMeetingDates(meetings);
      setLatestMeeting(latestMeetingDate);
      setUpcomingMeeting(upcomingMeetingDate);
    }
  }, [meetings]);

  return (
    <li className="py-6">
      <div className="flex space-x-4 sm:space-x-6">
        <div className="flex lg:items-center">
          <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden bg-indigo-50 rounded-full">
            <span className="font-medium text-indigo-800">{getInitials(client.name)}</span>
          </div>
        </div>
        <div className="w-full flex-1 flex-col sm:flex-row flex sm:space-x-6">
          <div className="min-w-0 flex-auto">
            <button
              onClick={() => goToClientPage(client.id)}
              className="max-w-full text-lg text-left text-wrap break-words font-semibold leading-6 text-gray-900 hover:text-indigo-700 transition-color duration-300"
            >
              {client.name}
            </button>

            <div className="mt-1 flex items-center gap-x-2 text-sm leading-5 text-gray-500">
              <p className="whitespace-nowrap">{meetings?.length} Meetings</p>

              {!latestMeeting && !upcomingMeeting && (
                <span className="flex items-center">
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 mr-2 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="truncate">No past or scheduled meetings</p>
                </span>
              )}
              {latestMeeting && (
                <span className="flex items-center">
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 mr-2 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="truncate">Last meeting on {latestMeeting}</p>
                </span>
              )}
              {upcomingMeeting && (
                <span className="flex items-center">
                  <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 mr-2 fill-current">
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <p className="truncate">Upcoming meeting on {upcomingMeeting}</p>
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-none items-center gap-x-4 mt-3 md:mt-0 md:ml-auto">
            <button
              onClick={() => goToClientPage(client.id)}
              className="inline-flex gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              // className="rounded-md bg-indigo-600 px-3.5 py-2 text-xs font-bold text-white shadow-sm ring-1 ring-inset hover:bg-indigo-500 transition ease-in-out duration-150 ring-gray-300 hover:bg-gray-50 sm:block "
            >
              View details
            </button>
          </div>
        </div>
      </div>
      <div className="w-full">
        {/* Error loading client meetings */}
        {error && <ErrorBanner message={error.message} />}
      </div>
    </li>
  );
}
