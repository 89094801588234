import { useState } from 'react';
import languages from '../../json/languages.json';
import SourceRequestButton from '../SourceRequestButton';

const meetingPurposes = [
  {
    purpose: 'Alex’s current living situation, including any changes or updates',
    sourceIndices: [6, 24, 25, 31, 32, 33]
  },
  {
    purpose: 'Alex’s current health and wellness, including any changes or updates',
    sourceIndices: [8, 19, 36]
  },
  {
    purpose: 'Alex’s current employment or education, including any changes or updates',
    sourceIndices: [4, 16, 23, 30, 41]
  }
];

export default function NoteSectionBodyGeneralInfo({
  handleSourceRequest
}: {
  handleSourceRequest: (sourceIndices: number[]) => void;
}) {
  const [isWorkerLanguageMatch, setIsWorkerLanguageMatch] = useState(true);

  return (
    <div className="p-5">
      {/* Meeting Purposes */}
      <ol className="list-decimal">
        <li>
          <label htmlFor="comment" className="text-base font-semibold leading-6 text-gray-900">
            Worker met with Client to discuss and check-in on the following items:
          </label>
          <ul className="list-[lower-alpha] ml-5 mt-1 space-y-2">
            {meetingPurposes.map((purpose) => (
              <li>
                <div className="flex items-center">
                  <input
                    type="text"
                    className="flex-grow mt-1 sm:mt-0 py-1.5 sm:px-3 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6 w-full"
                    defaultValue={purpose.purpose}
                  />
                  {/* Source Request Button */}
                  <SourceRequestButton onClick={() => handleSourceRequest(purpose.sourceIndices)} />
                </div>
              </li>
            ))}
          </ul>
        </li>

        {/* Language Accomodations */}
        <li className="pt-5">
          <h3 className="font-semibold pt-3">Language Accomodations</h3>
          <ol className="list-[lower-alpha] ml-5 mt-1">
            <li>
              <p className="flex text-base leading-6 text-gray-900 mt-1 sm:mt-0 py-1.5">
                Client's preferred language is
                <select className="sm:px-3 focus:text-gray-900 text-gray-600 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6">
                  <option disabled selected>
                    English
                  </option>
                  {Object.entries(languages).map(([, value]) => (
                    <option>{value.name}</option>
                  ))}
                </select>
                <SourceRequestButton onClick={() => handleSourceRequest([1, 2, 3])} />
              </p>
            </li>
            <li>
              <p className="flex text-base leading-6 text-gray-900 mt-1 sm:mt-0 py-1.5">
                {' '}
                Meeting was conducted in
                <select className="sm:px-3 focus:text-gray-900 text-gray-600 border-b outline-none border-gray-300 focus:border-gray-700 placeholder:text-gray-400 sm:text-base sm:leading-6">
                  <option disabled selected>
                    English
                  </option>
                  {Object.entries(languages).map(([, value]) => (
                    <option>{value.name}</option>
                  ))}
                </select>
                <SourceRequestButton onClick={() => handleSourceRequest([1, 2, 3])} />
              </p>
            </li>

            {/* Worker-Client Language Match */}
            <li>
              <div className="flex mt-5 mb-1">
                <p className="">
                  Does Worker's known languages match the Client’s preferred language?
                </p>
                <SourceRequestButton onClick={() => handleSourceRequest([1, 2, 3])} />
              </div>
              <div>
                {[true, false].map((value) => (
                  <div className="mt-2 flex items-center">
                    <input
                      id="worker_language_match"
                      name="worker_language_match"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      required
                      checked={isWorkerLanguageMatch === value}
                      onChange={() => setIsWorkerLanguageMatch(!isWorkerLanguageMatch)}
                    />
                    <label htmlFor="worker_language_match" className="ml-2 text-sm block">
                      {value ? 'Yes' : 'No'}
                    </label>
                  </div>
                ))}
              </div>
            </li>

            {/* Alternative Language Accomodation Used */}
            <li>
              <div>
                <div className="mt-5">
                  <fieldset>
                    <div className="flex mb-1">
                      <p className="text-base leading-6">
                        If no, what language accommodation was used?
                      </p>
                      <SourceRequestButton onClick={() => handleSourceRequest([1, 2, 3])} />
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="huey"
                        name="drone"
                        value="huey"
                        checked
                        required
                        disabled={isWorkerLanguageMatch}
                      />
                      <label className="ml-1 text-sm">
                        {' '}
                        Language Line - interpreter provided by Agency
                      </label>
                    </div>

                    <div className="mt-2">
                      <input
                        type="radio"
                        id="dewey"
                        name="drone"
                        value="dewey"
                        required
                        disabled={isWorkerLanguageMatch}
                      />
                      <label className="ml-1 text-sm"> Adult Family Member/Friend</label>
                    </div>

                    <div className="mt-2">
                      <input
                        type="radio"
                        id="louie"
                        name="drone"
                        value="louie"
                        required
                        disabled={isWorkerLanguageMatch}
                      />
                      <label className="ml-1 text-sm"> Another staff member</label>
                    </div>

                    <div className="mt-2">
                      <input
                        type="radio"
                        id="louie"
                        name="drone"
                        value="louie"
                        required
                        disabled={isWorkerLanguageMatch}
                      />
                      <label className="ml-1 text-sm">
                        {' '}
                        Rescheduled appointment to request an interpreter{' '}
                      </label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}
