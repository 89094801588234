import React, { ErrorInfo, ReactNode } from 'react';
import Sidebar from './Sidebar';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <Sidebar currentPageName={'Home'} />
          <div className="grid h-screen place-content-center bg-white px-4">
            <div className="text-center items-center">
              {/* <img className="w-96 mx-auto mb-4" src={SlippingPerson} alt="Icon" /> */}
              <h1 className="mb-4 text-5xl tracking-tight font-extrabold lg:text-7xl text-primary-600">
                Uh-oh!
              </h1>
              <p className="mb-4 text-3xl tracking-tight font-bold text-gray-700 md:text-4xl">
                An unexpected error occurred.
              </p>
              <p className="mb-4 text-lg font-light text-gray-500">
                If this behavior persists, please contact us at team@notewell.ai
              </p>
              <a
                href="#"
                className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
              >
                Back to Homepage
              </a>
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
