import { createContext, useContext, useMemo, useState } from 'react';
import { ProductTourState } from '../common/types';

const productTourState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false
};

export const ProductTourContext = createContext({
  state: productTourState,
  setState: () => undefined
});

export function ProductTourProvider(props: any) {
  const [state, setState] = useState<ProductTourState>(productTourState);
  const value = useMemo(() => ({ state, setState }), [setState, state]);

  return <ProductTourContext.Provider value={value} {...props} />;
}

export function useProductTourContext(): {
  setState: (updatedState: ProductTourState) => void;
  state: ProductTourState;
} {
  const context = useContext(ProductTourContext);
  if (!context) {
    throw new Error('useProductTourContext must be used within a AppProvider');
  }
  return context;
}
