import { Configuration } from '@azure/msal-browser';

const environment = process.env.REACT_APP_NODE_ENV || 'dev';

const apiBaseUrl =
  environment === 'prod'
    ? 'https://apim-notewell-prod-wus.azure-api.net/api'
    : environment === 'staging'
      ? 'https://appcs-backend-dev-wus-001.azurewebsites.net'
      : 'http://localhost:8080';

const redirectUri =
  environment === 'prod'
    ? 'https://app.notewell.ai'
    : environment === 'staging'
      ? 'https://orange-bush-09e248e1e.5.azurestaticapps.net'
      : 'http://localhost:3000/';

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_notewell_susi_flow1'
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://notewelldev.b2clogin.com/notewelldev.onmicrosoft.com/B2C_1_notewell_susi_flow1'
    }
  },
  authorityDomain: 'notewelldev.b2clogin.com'
};

// Client app registration id
const clientId =
  environment === 'prod'
    ? 'e9ba7cab-5855-451b-8d20-7e6e37d6012e'
    : '4e9af43f-e079-4760-98a3-aae34cd394dc';

export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: redirectUri,
    postLogoutRedirectUri: '/'
  },
  system: {
    allowNativeBroker: false // Disables WAM Broker
  }
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};

const scopes = [
  'https://notewelldev.onmicrosoft.com/notewell-server/notewell-server.write',
  'https://notewelldev.onmicrosoft.com/notewell-server/notewell-server.read'
];

export const protectedResources = {
  notewellServerApi: {
    endpoint: apiBaseUrl,
    scopes: scopes
  }
};
