import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

export default function ActionItems({
  actionItems,
  clientName
}: {
  actionItems: { action: string; done: boolean }[];
  clientName: string;
}) {
  const [items, setItems] = useState(actionItems);
  return actionItems && actionItems.length === 0 ? (
    <div className="rounded-md bg-indigo-50 border border-indigo-100 p-4 mt-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-indigo-700">You have no action items with {clientName} yet.</p>
        </div>
      </div>
    </div>
  ) : (
    // <table className="table-auto rounded-xl w-1/2 mt-2 divide-y divide-gray-150 border border-gray-150">
    //   <thead className="py-3.5 pl-4 pr-3 text-left text-sm">
    //     <tr className="divide-x">
    //       <th className="p-3">Action Step</th>
    //       <th className="p-3">Status</th>
    //     </tr>
    //     <tr></tr>
    //     <tr></tr>
    //   </thead>
    //   <tbody className="px-3 py-4 text-sm text-gray-700 divide-y divide-gray-150">
    //     {actionItems.map((action) => (
    //       <tr className="p-3 divide-x">
    //         <td className="w-[65%] p-3">
    //           <input type="text" defaultValue={action} className="w-full" />
    //         </td>
    //         <td className="w-[20%] p-3">
    //           <select>
    //             <option selected={false}>Done</option>
    //             <option selected={true}>Incomplete</option>
    //           </select>
    //         </td>
    //       </tr>
    //     ))}
    //   </tbody>
    // </table>

    <fieldset className="mt-4 px-2">
      <legend className="sr-only">Action Items</legend>
      <div className="space-y-5">
        {items.map((actionItem) => (
          <div className="relative flex items-start">
            <div className="flex h-3 items-center">
              <input
                id="comments"
                name="comments"
                type="checkbox"
                checked={actionItem.done}
                aria-describedby="comments-description"
                className="h-4 w-4 rounded border border-indigo-300 text-indigo-600 accent-indigo-100"
                onChange={() => {
                  setItems(
                    items.map((item) =>
                      item === actionItem ? { ...item, done: !item.done } : item
                    )
                  );
                }}
              />
            </div>
            <div className="ml-3 text-sm leading-3">
              <label htmlFor="comments" className="font-normal text-gray-700">
                {actionItem.action}
              </label>{' '}
              {/* <span id="comments-description" className="text-gray-500">
                <span className="sr-only">New comments </span>so you always know what's happening.
              </span> */}
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
